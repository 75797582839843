<template>
	<svg :width="width" :height="height" :style="styleName" viewBox="0 0 24 24" :class="className" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M15.6 1.96989L20.743 7.11289C20.8234 7.1909 20.8876 7.28411 20.9317 7.38711C20.9758 7.49011 20.999 7.60084 21 7.71289V17.9989C20.9992 18.2259 20.9087 18.4435 20.7481 18.604C20.5876 18.7646 20.37 18.8551 20.143 18.8559H7.286C7.05895 18.8551 6.84143 18.7646 6.68088 18.604C6.52034 18.4435 6.42979 18.2259 6.429 17.9989V2.56989C6.42979 2.34284 6.52034 2.12532 6.68088 1.96477C6.84143 1.80423 7.05895 1.71368 7.286 1.71289H15C15.214 1.71289 15.429 1.79889 15.6 1.96989ZM15 7.71289H18.943L15 3.76989V7.71289ZM8.143 3.42689V17.1419H19.286V9.42689H14.143C13.916 9.4261 13.6984 9.33556 13.5379 9.17501C13.3773 9.01446 13.2868 8.79694 13.286 8.56989V3.42689H8.143ZM3 5.14089H4.714V20.5689H15.857V22.2839H3.857C3.62961 22.2831 3.41178 22.1923 3.25118 22.0313C3.09057 21.8703 3.00026 21.6523 3 21.4249V5.14089Z" :fill="color"/>
	</svg>
</template>

<script>
  export default {
    name: 'IconDocuments',
	props: {
      'width': {
		type: Number,
		default: 24,
	  },
	  'height': {
        type: Number,
        default: 24,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#2B2A29'
	  },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
