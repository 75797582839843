export default {
  RESET_USER (state) {
    state = {
      user: null,
      profile: null,
      personal_data: null,
      main_documents: [],
      other_documents: []
    }
    state.user = null
  },
  SET_USER (state, payload) {
    state.user = payload
  },
  SET_PROFILE (state, payload) {
    state.profile = payload
  },
  SET_PERSONAL_DATA (state, payload) {
    state.personal_data = payload
  },
  SET_MAIN_DOCUMENTS (state, payload) {
    state.main_documents = payload
  },
  SET_OTHER_DOCUMENTS (state, payload) {
    state.other_documents = payload
  },
}
