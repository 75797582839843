<template>
	<div class="mw-900px">
		<div class="alert alert-success d-flex align-items-center mb-24px" v-if="user.profile_verified">
			<i class="fa fa-check me-12px"></i>
			Поля анкеты проверены
		</div>
		<router-link to="/profile/documents" class="btn btn-outline-success px-30px py-10px">Запрос на актуализацию данных</router-link>
		<div class="row mt-24px">
			<div class="col-12 col-sm-6 mb-15px position-relative">
				<label class="form-label">Фамилия*</label>
				<div class="border border-radius-sm py-8px px-16px">{{form['surname']}}</div>
			</div>

			<div class="col-12 col-sm-6 mb-15px position-relative">
				<label class="form-label">Имя*</label>
				<div class="border border-radius-sm py-8px px-16px">{{form['name']}}</div>
			</div>

			<div class="col-12 col-sm-6 mb-15px position-relative">
				<label class="form-label">Отчество</label>
				<div class="border border-radius-sm py-8px px-16px">{{form['patronymic']}}</div>
			</div>

			<div class="col-12 col-sm-6 mb-15px position-relative">
				<label class="form-label">СНИЛС*</label>
				<div class="border border-radius-sm py-8px px-16px">{{form['snils']}}</div>
			</div>

			<div class="col-12 col-sm-6 mb-15px position-relative">
				<label class="form-label">Телефон*</label>
				<div class="border border-radius-sm py-8px px-16px">{{form['phone']}}</div>
			</div>

			<div class="col-12 col-sm-6 mb-15px position-relative">
				<label class="form-label">Эл. адрес*</label>
				<div class="border border-radius-sm py-8px px-16px">{{form['email']}}</div>
			</div>
		</div>
	</div>
</template>

<script>

  export default {
    name: 'PersonalDataProfileView',
    data() {
      return {
        form: {
          surname: '',
          name: '',
          patronymic: '',
          phone: '',
          email: '',
          snils: '',
        },
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      isListener() {
        return this.user && this.user.role === 'listener'
      },
      personalData() {
        return JSON.parse(JSON.stringify(this.$store.getters['user/personal_data']))
      },
    },
    watch: {
      personalData(newVal, oldVal) {
        this.form.surname = newVal.surname
        this.form.name = newVal.name
        this.form.patronymic = newVal.patronymic
        this.form.phone = this.$formatPhone(newVal.phone)
        this.form.email = newVal.email
        this.form.snils = newVal.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)
      },
    },
    mounted() {
      this.$store.dispatch('user/fetchGetUserPersonalData')
    },
    methods: {},
  }
</script>
