import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'

import './mixins'
import './plugins'
import './directives'

import App from './App.vue'

Vue.config.productionTip = false

/*
Vue.config.errorHandler = function(err, vm, info) {
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
  console.log(vm)
}

Vue.config.warnHandler = function(msg, vm, trace) {
  console.log(`Warn: ${msg}\nTrace: ${trace}`);
}
*/

Vue.use(Vuex)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
