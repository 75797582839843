<template>
	<div>
		<div class="border border-2 border-radius-lg border-gray px-24px py-18px mb-24px">
			<div class="d-flex justify-content-between align-items-center" :class="payment ? 'mb-12px' : ''">
				<div class="fs-24px fw-500">Реквизиты плательщика</div>
				<PayerDetailsEditModal :data="payment" :id-order="idOrder" v-if="payment" :id-bid="idBid">
					<template slot="button">
						<a href="javascript:;">
							<IconPen />
						</a>
					</template>
				</PayerDetailsEditModal>
				<div v-else>
					<PayerDetailsEditModal :id-order="idOrder" :id-bid="idBid">
						<template slot="button">
							<a href="javascript:void(0)" class="d-inline-flex align-items-center fw-500 mt-32px text-decoration-none text-dark">
									<span class="d-flex align-items-center justify-content-center w-50px h-50px border-radius-round bg-gray-back me-12px">
										<IconPlus />
									</span>
								Добавить
							</a>
						</template>
					</PayerDetailsEditModal>
				</div>
			</div>
			<div class="d-grid columns-grid" v-if="payment && (payment.type_face === 'ur' || payment.type_face === 'ip')">
				<div class="columns-item" v-if="payment.abbreviation">{{payment.abbreviation}}</div>
				<div class="columns-item" v-if="payment.inn">ИНН: {{payment.inn}}</div>
				<div class="columns-item" v-if="payment.kpp">КПП: {{payment.kpp}}</div>
				<div class="columns-item" v-if="payment.ogrn">ОГРН: {{payment.ogrn}}</div>
				<div class="columns-item" v-if="payment.city">Город: {{payment.city}}</div>
				<div class="columns-item" v-if="payment.registration_address">Адрес: {{payment.registration_address}}</div>
			</div>
			<div v-if="payment && payment.type_face === 'fiz'">
				<div class="columns-item" v-if="payment.name">{{[payment.surname, payment.name, payment.patronymic].join(' ')}}</div>
				<div class="columns-item" v-if="payment.snils">СНИЛС: {{payment.snils}}</div>
			</div>
		</div>
	</div>
</template>

<script>
  import IconTrash from '@/components/icons/IconTrash'
  import IconPlus from '@/components/icons/IconPlus'
  import IconPen from '@/components/icons/IconPen'
  import DeleteListenerModal from './modal/DeleteListenerModal'
  import PayerDetailsEditModal from '@/components/parts/order/modal/PayerDetailsEditModal'

    export default {
      name: 'PayerDetail',
	  props: ['payment', 'idOrder', 'idBid'],
      components: { PayerDetailsEditModal, DeleteListenerModal, IconPlus, IconTrash, IconPen },
      data() {
        return {
        }
      },
	  methods: {
	  }
    }
</script>
