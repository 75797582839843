export default {
  RESET_DOCUMENTS(state) {
    state = {
      items: [],
      types: []
    }
  },
  SET_DOCUMENTS_ITEMS(state, payload) {
    state.items = payload.items.map(item => {
      return {
        ...item,
        idOrder: Number(payload.idOrder),
        idBid: Number(payload.idBid)
      }
    })
  },
  SET_DOCUMENTS_TYPES(state, payload) {
    state.types = payload
  },
}
