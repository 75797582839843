import axios from 'axios'

export default {
  async fetchDictionary({ rootState, commit }, payload) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + `/dictionary/${payload.type}${payload.subtype ? '/' + payload.subtype : ''}/list`,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data
      result.data ? commit('SET_DICTIONARY', { type: payload.subtype || payload.type, items: result.data }) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
}
