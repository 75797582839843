<template>
	<div>
		<div class="border-radius-lg border mb-20px">
			<div class="p-0 bg-white border-0 d-flex align-items-center justify-content-between p-20px cursor-pointer" @click="visible = !visible">
				<div class="fs-20px fw-600" v-if="bid.program">Программа "{{bid.program.name}}"</div>
				<IconChevronDown :style="`transition: .2s ease all; transform: rotate(${visible ? '0deg' : '180deg'})`" />
			</div>
			<b-collapse :id="`accordion_${bid.id}`" v-model="visible" :accordion="`my-accordion_${bid.id}`">
				<div class="p-0 bg-white border-0  px-20px pb-20px">

					<ValidationObserver tag="form"
										@submit.prevent="submitForm"
										ref="observer-form"
										v-slot="{ invalid }"
										method="POST">

						<ValidationProvider tag="div" rules="required" name="Cогласие на прохождение выбранной программы" v-slot="{ errors }">
							<div :class="'d-inline-flex px-12px py-8px border-radius-sm bg-gray-10 position-relative mb-20px form-check' + (errors.length ? ' is-invalid' : '')">
								<input type="checkbox"
									   v-model="form.consent"
									   :name="`form-field-consent_${bid.id}`"
									   :id="`form-field-consent_${bid.id}`"
									   class="form-check-input ms-0 form-check-input-bordered"
									   v-if="!bid.give_consent"/>
								<span :disabled="true"
									  :class="`form-check-input checked ms-0 form-check-input-bordered`"
									  v-else/>
								<label :for="`form-field-consent_${bid.id}`"
									   class="form-label ms-12px mb-0">Даю свое согласие на прохождение выбранной программы</label>
							</div>
							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="`form-field-agreement_err`">{{err}}</span>
							</div>
						</ValidationProvider>

						<div class="row mb-30px" v-if="bid.program">
							<div class="col-12 col-sm-4 d-flex flex-column gap-3">
								<dl class="d-flex gap-2 mb-0" v-if="bid.program.count_clock">
									<dt class="text-muted w-150px">Объем часов</dt>
									<dd class="mb-0">{{bid.program.count_clock}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="bid.program.form_education_program">
									<dt class="text-muted w-150px">Форма обучения</dt>
									<dd class="mb-0">{{bid.program.form_education_program.name}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="bid.program.type_educational_program">
									<dt class="text-muted w-150px">Вид программы</dt>
									<dd class="mb-0">{{bid.program.type_educational_program.name}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0">
									<dt class="text-muted w-150px">Вид документа</dt>
									<dd class="mb-0">Удостоверение</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="price">
									<dt class="text-muted w-150px">Стоимость</dt>
									<dd class="mb-0">{{$formatPrice(price)}}</dd>
								</dl>
							</div>
							<div class="col-12 col-sm-8 d-flex flex-column">
								<div class="h6 mb-15px">Блоки программы</div>

								<div class="d-flex flex-column gap-2">
									<div v-for="group in bid.program.group_program" :key="`group_${bid.id}_${group.id}`">
										<div class="fw-500">{{group.name}}</div>
										<ul class="list-unstyled d-flex flex-column gap-1 mb-0" v-if="group.blocks.length">
											<li v-for="block in group.blocks" :key="`block_${bid.id}_${block.id}`">{{block.name}}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<!--<div class="fs-20px fw-600 mb-20px">Выберите параметры обучения</div>

						<div class="fs-18px fw-600 mb-12px">Дополнительные блоки:</div>

						<div class="d-flex flex-wrap flex-rows align-items-stretch mb-30px">
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault1">
								<input class="form-check-input form-check-input-bordered ms-0" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
								<div class="form-check-label ms-12px">
									<ul class="list-unstyled mb-0 d-flex flex-column gap-2">
										<li>Блок 1</li>
										<li>Блок 2</li>
										<li>Блок 3</li>
									</ul>
								</div>
							</label>
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault2">
								<input class="form-check-input form-check-input-bordered ms-0" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
								<div class="form-check-label ms-12px">
									<ul class="list-unstyled mb-0 d-flex flex-column gap-2">
										<li>Блок 1</li>
										<li>Блок 2</li>
										<li>Блок 3</li>
									</ul>
								</div>
							</label>
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault3">
								<input class="form-check-input form-check-input-bordered ms-0" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
								<div class="form-check-label ms-12px">
									<ul class="list-unstyled mb-0 d-flex flex-column gap-2">
										<li>Блок 1</li>
										<li>Блок 2</li>
										<li>Блок 3</li>
									</ul>
								</div>
							</label>
						</div>

						<div class="row mb-15px">
							<div class="col-12 col-sm-4">
								<div class="fs-18px fw-600 mb-12px">Дата начала обучения</div>
								<ValidationProvider tag="div" class="col-12 mb-15px position-relative" rules="required" name="Дата начала обучения" v-slot="{ errors }">
									<InputField
											:type-field="`date`"
											:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
											v-model="form.date_start">
										<template slot="icon">
											<span class="pointer-event-none position-absolute end-0 top-0 w-40px h-40px d-flex align-items-center justify-content-center"><i class="fa fa-calendar"></i></span>
										</template>
									</InputField>
									<div v-if="errors.length" class="invalid-tooltip">
										<span v-for="err in errors" :key="'field-date_start_err'">{{err}}</span>
									</div>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-4">
								<div class="fs-18px fw-600 mb-12px">Формат обучения</div>
								<ValidationProvider tag="div" class="col-12 mb-15px position-relative" rules="required" name="Формат обучения" v-slot="{ errors }">
									<v-select :options="forms" :reduce="item => item.code" label="label" :clearable="false" :searchable="false" v-model="form.study_form"></v-select>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-4">
								<div class="fs-18px fw-600 mb-12px">Объем учебной нагрузки в день</div>
								<ValidationProvider tag="div" class="col-12 mb-15px position-relative" rules="required" name="Объем учебной нагрузки в день" v-slot="{ errors }">
									<v-select :options="hours" :reduce="item => item.code" label="label" :clearable="false" :searchable="false" v-model="form.count_hours_per_day"></v-select>
								</ValidationProvider>
							</div>
						</div>

						<div class="fs-18px fw-600 mb-12px">Сервисы:</div>

						<div class="d-flex flex-wrap flex-rows align-items-stretch mb-30px">
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault21">
								<input class="form-check-input form-check-input-bordered ms-0" type="checkbox" name="flexRadioDefault2" id="flexRadioDefault21">
								<div class="form-check-label ms-12px flex-1">
									<div class="d-flex justify-content-between fw-600 w-100 gap-2">
										<span>Кофебрейк</span>
										<span>10 000 Р</span>
									</div>
									<div class="mt-12px text-gray-600">
										Краткое описание
									</div>
								</div>
							</label>
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault22">
								<input class="form-check-input form-check-input-bordered ms-0" type="checkbox" name="flexRadioDefault2" id="flexRadioDefault22">
								<div class="form-check-label ms-12px flex-1">
									<div class="d-flex justify-content-between fw-600 w-100 gap-2">
										<span>Раздаточные материалы</span>
										<span>2 000 Р</span>
									</div>
									<div class="mt-12px text-gray-600">
										Краткое описание
									</div>
								</div>
							</label>
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault23">
								<input class="form-check-input form-check-input-bordered ms-0" type="checkbox" name="flexRadioDefault2" id="flexRadioDefault23">
								<div class="form-check-label ms-12px flex-1">
									<div class="d-flex justify-content-between fw-600 w-100 gap-2">
										<span>Кофебрейк</span>
										<span>10 000 Р</span>
									</div>
									<div class="mt-12px text-gray-600">
										Краткое описание
									</div>
								</div>
							</label>
						</div>-->

						<div class="mb-30px">
							<div class="fs-16px fw-600 mb-12px">Программой предусмотрено предоставление мотивационного письма</div>

							<a :href="bid.motivation_letter.file_link" :download="bid.motivation_letter.file_name" class="text-decoration-underline" v-if="bid.motivation_letter && bid.motivation_letter.id">{{bid.motivation_letter.file_name}}</a>
							<AddMotivationLetterModal :id-program="_id" @setFile="setFile" v-else>
								<template slot="button">
									<a href="javascript:;" class="text-decoration-underline">{{form.motivation_letter ? form.motivation_letter.name : 'Правила предоставления мотивационного письма'}}</a>
								</template>
							</AddMotivationLetterModal>
						</div>

						<!--<div class="fs-16px fw-600 mb-12px">Предоставить возможность отслеживать обучение контактному лицу</div>

						<div class="d-flex flex-column gap-3">
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.progress_training"
									   :name="`form-field-progress_training`"
									   :id="`form-field-progress_training`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-progress_training`"
									   class="form-label ms-12px mb-0">ход обучение (прогресс)</label>
							</div>
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.extracts_from_orders"
									   :name="`form-field-extracts_from_orders`"
									   :id="`form-field-extracts_from_orders`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-extracts_from_orders`"
									   class="form-label ms-12px mb-0">выписки из приказов о зачислении и об отчислении</label>
							</div>
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.progress_report"
									   :name="`form-field-progress_report`"
									   :id="`form-field-progress_report`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-progress_report`"
									   class="form-label ms-12px mb-0">ведомость успеваемости</label>
							</div>
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.attendance_sheet"
									   :name="`form-field-attendance_sheet`"
									   :id="`form-field-attendance_sheet`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-attendance_sheet`"
									   class="form-label ms-12px mb-0">табель посещаемости</label>
							</div>
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.documents_on_completion"
									   :name="`form-field-documents_on_completion`"
									   :id="`form-field-documents_on_completion`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-documents_on_completion`"
									   class="form-label ms-12px mb-0">документы по итогу обучения (Диплом/Удостоверение/Справка об обучении)</label>
							</div>
						</div>-->

						<div class="d-flex flex-wrap gap-4 mt-30px" v-if="!bid.give_consent">
							<button type="submit" class="btn btn-primary px-30px py-10px" :disabled="!form.consent">Отправить</button>

							<!--<router-link :to="''" class="btn btn-outline-default text-dark px-30px py-10px">Подробнее</router-link>-->
						</div>

					</ValidationObserver>
				</div>
			</b-collapse>
		</div>
	</div>
</template>

<script>
  import IconChevronDown from '@/components/icons/IconChevronDown'
  import InputField from '@/components/elements/Input'
  import AddMotivationLetterModal from '@/components/parts/education/AddMotivationLetterModal'
  import uniqid from 'uniqid'
  import axios from 'axios'

  export default {
    name: 'EducationProgramsCard',
    components: { AddMotivationLetterModal, InputField, IconChevronDown },
    props: ['bid'],
    data() {
      return {
        visible: false,
        form: {
          consent: false,
          date_start: '',
          study_form: 1,
          count_hours_per_day: 1,
          progress_training: false,
          extracts_from_orders: false,
          documents_on_completion: false,
          attendance_sheet: false,
          progress_report: false,
          motivation_letter: null,
        },
        hours: [
          { code: 1, label: 1 },
          { code: 2, label: 2 },
          { code: 3, label: 3 },
          { code: 4, label: 4 },
          { code: 5, label: 5 },
          { code: 6, label: 6 },
          { code: 7, label: 7 },
          { code: 8, label: 8 },
        ],
        forms: [
          { code: 1, label: 'очная' },
          { code: 2, label: 'заочная' },
        ],
      }
    },
    computed: {
      _id() {
        return uniqid()
      },
      price() {
        let price = 0
        if (this.bid && this.bid.program && this.bid.program.group_program) {
          this.bid.program.group_program.forEach(group => {
            if (group.blocks.length) {
              group.blocks.forEach(block => {
                price += block.price
              })
            }
          })
        }
        return price
      },
      token() {
        return this.$store.getters['auth/token']
      },
      bidForWatch() {
        return JSON.parse(JSON.stringify(this.bid))
	  }
    },
    watch: {
      bidForWatch(newVal) {
        console.log('bid', newVal)
        this.visible = !newVal.give_consent
		this.form.consent = newVal.give_consent
      },
    },
    mounted() {
      console.log(this.bid)
      if (this.bid) {
        this.visible = !this.bid.give_consent
        this.form.consent = this.bid.give_consent
      }
    },
    methods: {
      setFile(file) {
        console.log('file', file)
        this.form.motivation_letter = file
      },
      async submitForm() {
        const isValid = await this.$refs['observer-form'].validate()
        if (!isValid) return false

        if (!this.form.motivation_letter) {
          this.$notify({
            group: 'center',
            title: `Ошибка!`,
            text: 'Прикрепите мотивационное письмо',
            type: 'error',
          })
		  return false
        }

        let formData = new FormData()

        formData.append('motivation_letter', this.form.motivation_letter)
        formData.append('consent', this.form.consent ? 1 : 0)

        let path = `/listeners/profile/bids/${this.bid.id}/confirm/send`

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: formData,
        })
          .then(res => res.data)
          .then(res => {
            if (res.message) {
              this.$notify({
                group: 'center',
                title: `Ошибка!`,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$store.dispatch('bids/fetchBidItems')
            }
          })
      },
    },
  }
</script>
