const legal = {
  map: {
    inn: {
      tag: 'input',
      type: 'suggestion',
      suggestions: [],
      showSuggestions: false,
      label: 'ИНН*',
      rules: 'required|min:10|max:10|numeric',
      maxlength: 10,
      width: 4,
      filter: `[0-9]`,

      method: '/search/payer/by_inn',

      field_name: 'inn',
    },
    full_ur_name: {
      tag: 'input',
      type: 'text',
      label: 'Полное юридическое название',
      rules: '',
      width: 8,
      field_name: 'full_ur_name',
      filter: `[0-9а-яА-ЯёЁ \-]`,
    },
    kpp: {
      tag: 'input',
      type: 'text',
      label: 'КПП*',
      rules: 'required|digits:9',
      filter: `[0-9]`,
      width: 4,
      maxlength: 9,
      field_name: 'kpp',
    },
    abbreviation: {
      tag: 'input',
      type: 'text',
      label: 'Сокращенное название',
      rules: '',
      width: 8,
      field_name: 'abbreviation',
      filter: `[0-9а-яА-ЯёЁ \-]`,
    },
    ogrn: {
      tag: 'input',
      type: 'text',
      label: 'ОГРН*',
      rules: 'required|digits:13',
      filter: `[0-9]`,
      width: 4,
      maxlength: 13,
      field_name: 'ogrn',
    },
    city: {
      tag: 'input',
      type: 'text',
      label: 'Город*',
      rules: 'required',
      width: 4,
      field_name: 'city',
      filter: `[0-9а-яА-ЯёЁ \-]`,
    },
    index: {
      tag: 'input',
      type: 'text',
      label: 'Индекс*',
      rules: 'required|digits:6',
      filter: `[0-9]`,
      maxlength: 6,
      width: 4,
      field_name: 'index',
    },
    ur_address: {
      tag: 'textarea',
      label: 'Юридический адрес* (с индексом)',
      rules: '',
      width: 6,
      field_name: 'ur_address',
      filter: `[0-9а-яА-ЯёЁ ,.\/\-]`,
    },
    actual_address: {
      tag: 'textarea',
      label: 'Фактический адрес* (с индексом)',
      rules: '',
      width: 6,
      field_name: 'actual_address',
      filter: `[0-9а-яА-ЯёЁ ,.\/\-]`,
    },
    check: {
      tag: 'input',
      type: 'checkbox',
      label: 'Совпадает с юридическим адресом',
      rules: '',
      width: 6,
      offset: 6,
      field_name: 'check',
    },
    fio_head: {
      tag: 'input',
      type: 'text',
      label: 'ФИО руководителя*',
      rules: 'required',
      width: 8,
      field_name: 'fio_head',
    },
    job_title: {
      tag: 'input',
      type: 'text',
      label: 'Должность руководителя*',
      rules: 'required',
      width: 4,
      field_name: 'job_title',
    },
    acts_basis: {
      tag: 'input',
      type: 'text',
      label: 'Действует на основании*',
      rules: 'required',
      width: 12,
      field_name: 'acts_basis',
    },
    fio_rod_head: {
      tag: 'input',
      type: 'text',
      label: 'ФИО руководителя в родительном падеже*',
      rules: 'required',
      width: 12,
      field_name: 'fio_rod_head',
    },
    title: {
      tag: 'div',
      class: 'fs-18px fw-bold mb-16px mt-30px',
      label: 'Банковские реквизиты',
      rules: '',
      width: 12,
    },
    bik_bank: {
      tag: 'input',
      label: 'БИК банка*',
      rules: 'required|digits:9',
      filter: `[0-9]`,
      placeholder: 'ХХХХХХХХХ',
      width: 4,
      maxlength: 9,

      type: 'suggestion',
      fields: {
        bik_bank: 'bik_bank',
        name_bank: 'name_bank',
        ks: 'ks',
      },
      suggestions: [],
      showSuggestions: false,

      method: '/search/payer/by_bik',

      field_name: 'bik_bank',
    },
    name_bank: {
      tag: 'input',
      type: 'text',
      label: 'Название банка*',
      filter: `[0-9а-яА-ЯёЁ \-]`,
      placeholder: 'Введите название',
      rules: 'required',
      width: 4,
      field_name: 'name_bank',
    },
    rc: {
      tag: 'input',
      type: 'text',
      label: 'р/с*',
      placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
      rules: 'required|digits:20',
      filter: `[0-9]`,
      width: 4,
      maxlength: 20,
      field_name: 'rc',
    },
    ks: {
      tag: 'input',
      type: 'text',
      label: 'к/с*',
      placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
      rules: 'required|digits:20',
      filter: `[0-9]`,
      width: 4,
      maxlength: 20,
      field_name: 'ks',
    },
    kbk: {
      tag: 'input',
      type: 'text',
      label: 'КБК',
      placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
      rules: 'digits:20',
      filter: `[0-9]`,
      width: 4,
      maxlength: 20,
      field_name: 'kbk',
    },
    personal_account: {
      tag: 'input',
      type: 'text',
      label: 'Лицевой счет',
      placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
      rules: 'digits:20',
      filter: `[0-9]`,
      maxlength: 20,
      width: 4,
      field_name: 'personal_account',
    },
    title2: {
      tag: 'div',
      class: 'mt-9px',
      label: '',
      rules: '',
      width: 12,
    },
    concluded_accordance: {
      tag: 'input',
      type: 'radio',
      rules: 'required',
      width: 12,
      label: 'Основание для действий',
      field_name: 'concluded_accordance',
      values: [
        {
          label: 'Заключается согласно 44-ФЗ',
          value: '44fz',
        },
        {
          label: 'Заключается согласно 223-ФЗ',
          value: '223fz',
        },
        {
          label: 'Заключается с коммерческой организацией',
          value: 'commerce',
        },
      ],
    },
  },
  form: {
    type_face: 'ur',
    inn: '',
    kpp: '',
    ogrn: '',
    city: '',
    index: '',
    abbreviation: '',
    full_ur_name: '',
    ur_address: '',
    actual_address: '',
    bik_bank: '',
    name_bank: '',
    rc: '',
    ks: '',
    kbk: '',
    personal_account: '',
    fio_rod_head: '',
    job_title: '',
    acts_basis: '',
    concluded_accordance: '',
    surname: '',
    name: '',
    patronymic: '',
    snils: '',
    check: false,
  }
}

const ip = {
  map: {
    inn: {
      tag: 'input',
      type: 'suggestion',
      suggestions: [],
      showSuggestions: false,
      label: 'ИНН*',
      rules: 'required|min:12|max:12|numeric',
      maxlength: 12,
      width: 4,

      method: '/search/payer/by_inn',

      field_name: 'inn',
    },
    full_ur_name: {
      tag: 'input',
      type: 'text',
      label: 'Полное юридическое название',
      rules: '',
      width: 8,
      field_name: 'full_ur_name',
      filter: `[0-9а-яА-ЯёЁ \-]`,
    },
    abbreviation: {
      tag: 'input',
      type: 'text',
      label: 'Сокращенное название',
      rules: '',
      width: 8,
      field_name: 'abbreviation',
      filter: `[0-9а-яА-ЯёЁ \-]`,
    },
    ogrn: {
      tag: 'input',
      type: 'text',
      label: 'ОГРН*',
      rules: 'required|digits:15',
      filter: `[0-9]`,
      width: 4,
      maxlength: 15,
      field_name: 'ogrn',
    },
    city: {
      tag: 'input',
      type: 'text',
      label: 'Город*',
      rules: 'required',
      width: 8,
      field_name: 'city',
      filter: `[0-9а-яА-ЯёЁ \-]`,
    },
    index: {
      tag: 'input',
      type: 'text',
      label: 'Индекс*',
      rules: 'required|digits:6',
      filter: `[0-9]`,
      maxlength: 6,
      width: 4,
      field_name: 'index',
    },
    ur_address: {
      tag: 'textarea',
      label: 'Юридический адрес* (с индексом)',
      rules: '',
      width: 6,
      field_name: 'ur_address',
      filter: `[0-9а-яА-ЯёЁ ,.\/\-]`,
    },
    actual_address: {
      tag: 'textarea',
      label: 'Фактический адрес* (с индексом)',
      rules: '',
      width: 6,
      field_name: 'actual_address',
      filter: `[0-9а-яА-ЯёЁ ,.\/\-]`,
    },
    check: {
      tag: 'input',
      type: 'checkbox',
      label: 'Совпадает с юридическим адресом',
      rules: '',
      width: 6,
      offset: 6,
      field_name: 'check',
    },
    fio_head: {
      tag: 'input',
      type: 'text',
      label: 'ФИО*',
      rules: 'required',
      width: 12,
      field_name: 'fio_head',
    },
    fio_rod_head: {
      tag: 'input',
      type: 'text',
      label: 'ФИО в родительном падеже*',
      rules: 'required',
      width: 12,
      field_name: 'fio_rod_head',
    },
    passport_series: {
      tag: 'input',
      type: 'mask',
      mask: '1111',
      placeholder: 'XXXX',
      label: 'Серия паспорта*',
      rules: 'required',
      width: 4,

      field_name: 'passport_series',
    },
    passport_number: {
      tag: 'input',
      type: 'mask',
      mask: '111111',
      placeholder: 'XXXXXX',
      label: 'Номер паспорта*',
      rules: 'required',
      width: 4,

      field_name: 'passport_number',
    },
    passport_date_issue: {
      tag: 'input',
      type: 'date',
      label: 'Дата выдачи*',
      rules: 'required',
      width: 4,

      field_name: 'date_issue',
    },
    passport_issued_by_whom: {
      tag: 'input',
      type: 'text',
      label: 'Кем выдан*',
      rules: 'required',
      width: 12,

      field_name: 'issued_by_whom',
    },
    title: {
      tag: 'div',
      class: 'fs-18px fw-bold mb-16px mt-30px',
      label: 'Банковские реквизиты',
      rules: '',
      width: 12,
    },
    bik_bank: {
      tag: 'input',
      label: 'БИК банка*',
      rules: 'required|digits:9',
      filter: `[0-9]`,
      placeholder: 'ХХХХХХХХХ',
      width: 4,
      maxlength: 9,

      type: 'suggestion',
      fields: {
        bik_bank: 'bik_bank',
        name_bank: 'name_bank',
        ks: 'ks',
      },
      suggestions: [],
      showSuggestions: false,

      method: '/search/payer/by_bik',

      field_name: 'bik_bank',
    },
    name_bank: {
      tag: 'input',
      type: 'text',
      label: 'Название банка*',
      placeholder: 'Введите название',
      rules: 'required',
      width: 8,
      field_name: 'name_bank',
      filter: `[0-9а-яА-ЯёЁ \-]`,
    },
    rc: {
      tag: 'input',
      type: 'text',
      label: 'р/с*',
      placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
      rules: 'required|digits:20',
      filter: `[0-9]`,
      width: 6,
      maxlength: 20,
      field_name: 'rc',
    },
    ks: {
      tag: 'input',
      type: 'text',
      label: 'к/с*',
      placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
      rules: 'required|digits:20',
      filter: `[0-9]`,
      width: 6,
      maxlength: 20,
      field_name: 'ks',
    },
  },
  form: {
    type_face: 'ip',
    inn: '',
    full_ur_name: '',
    abbreviation: '',
    ogrn: '',
    city: '',
    index: '',
    ur_address: '',
    actual_address: '',
    check: false,
    fio_head: '',
    job_title: '',
    fio_rod_head: '',
    bik_bank: '',
    name_bank: '',
    rc: '',
    ks: '',
  }
}

const fiz = {
  map: {
    surname: {
      tag: 'input',
      type: 'text',
      label: 'Фамилия*',
      rules: 'required',
      width: 4,

      field_name: 'surname',
    },
    name: {
      tag: 'input',
      type: 'text',
      label: 'Имя*',
      rules: 'required',
      width: 4,

      field_name: 'name',
    },
    patronymic: {
      tag: 'input',
      type: 'text',
      label: 'Отчество',
      rules: '',
      width: 4,

      field_name: 'patronymic',
    },
    snils: {
      tag: 'input',
      type: 'mask',
      mask: '111-111-111-11',
      label: 'СНИЛС*',
      rules: 'required|snils',
      width: 8,

      field_name: 'snils',
    },
    inn: {
      tag: 'input',
      type: 'suggestion',
      suggestions: [],
      showSuggestions: false,
      label: 'ИНН*',
      rules: 'required|min:12|max:12|numeric',
      maxlength: 12,
      width: 4,

      method: '/search/payer/by_inn',

      field_name: 'inn',
    },
    registration_address: {
      tag: 'textarea',
      label: 'Адрес регистрации* (с индексом)',
      rules: '',
      width: 6,

      field_name: 'registration_address',
      filter: `[0-9а-яА-ЯёЁ ,.\/\-]`,
    },
    actual_address: {
      tag: 'textarea',
      label: 'Адрес проживания* (с индексом)',
      rules: '',
      width: 6,

      field_name: 'actual_address',
      filter: `[0-9а-яА-ЯёЁ ,.\/\-]`,
    },
    check: {
      tag: 'input',
      type: 'checkbox',
      label: 'Совпадает с адресом регистрации',
      rules: '',
      width: 6,
      offset: 6,

      field_name: 'check',
    },
    fio_rod: {
      tag: 'input',
      type: 'text',
      label: 'ФИО в родительном падеже*',
      rules: 'required',
      width: 12,
      field_name: 'fio_rod_head',
    },
    passport_series: {
      tag: 'input',
      type: 'mask',
      mask: '1111',
      placeholder: 'XXXX',
      label: 'Серия паспорта*',
      rules: 'required',
      width: 4,

      field_name: 'passport_series',
    },
    passport_number: {
      tag: 'input',
      type: 'mask',
      mask: '111111',
      placeholder: 'XXXXXX',
      label: 'Номер паспорта*',
      rules: 'required',
      width: 4,

      field_name: 'passport_number',
    },
    passport_date_issue: {
      tag: 'input',
      type: 'date',
      label: 'Дата выдачи*',
      rules: 'required',
      width: 4,

      field_name: 'date_issue',
    },
    passport_issued_by_whom: {
      tag: 'input',
      type: 'text',
      label: 'Кем выдан*',
      rules: 'required',
      width: 12,

      field_name: 'issued_by_whom',
    },
    title: {
      tag: 'div',
      class: 'fs-18px fw-bold mb-16px mt-30px',
      label: 'Банковские реквизиты',
      rules: '',
      width: 12,

    },
    bik_bank: {
      tag: 'input',
      label: 'БИК банка*',
      rules: 'required|digits:9',
      filter: `[0-9]`,
      placeholder: 'ХХХХХХХХХ',
      width: 4,
      maxlength: 9,

      type: 'suggestion',
      fields: {
        bik_bank: 'bik_bank',
        name_bank: 'name_bank',
        ks: 'ks',
      },
      suggestions: [],
      showSuggestions: false,

      method: '/search/payer/by_bik',

      field_name: 'bik_bank',
    },
    name_bank: {
      tag: 'input',
      type: 'text',
      label: 'Название банка*',
      placeholder: 'Введите название',
      rules: 'required',
      width: 4,
      filter: `[0-9а-яА-ЯёЁ \-]`,

      field_name: 'name_bank',
    },
    rc: {
      tag: 'input',
      type: 'text',
      label: 'р/с*',
      placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
      rules: 'required|digits:20',
      filter: `[0-9]`,
      width: 4,
      maxlength: 20,

      field_name: 'rc',
    },
    ks: {
      tag: 'input',
      type: 'text',
      label: 'к/с*',
      placeholder: 'ХХХХХХХХХХХХХХХХХХХХ',
      rules: 'required|digits:20',
      filter: `[0-9]`,
      width: 4,
      maxlength: 20,

      field_name: 'ks',
    },
  },
  form: {
    type_face: 'fiz',
    surname: '',
    name: '',
    patronymic: '',
    snils: '',
    registration_address: '',
    actual_address: '',
    check: false,
    bik_bank: '',
    name_bank: '',
    rc: '',
    ks: '',
  }
}

export default {
  fiz: fiz,
  ip: ip,
  legal: legal,
}
