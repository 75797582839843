<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb d-none d-md-flex">
			<li class="breadcrumb-item">
				<router-link to="/">Главная</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link to="/profile">Личный кабинет</router-link>
			</li>
			<li class="breadcrumb-item active">Профиль</li>
		</ol>
		<!-- END breadcrumb -->
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-end gap-4 mb-25px">
			<h1 class="page-header mb-0">Выберите параметры обучения</h1>
			<span class="fs-14px lh-lg text-danger">*Программы акцептованные свернуты, НЕакцептованные - развернуты и отображаются сверху</span>
		</div>
		<!-- END page-header -->
		<div class="mw-1200px">
			<EducationProgramsCard v-for="bid in bids" v-if="bids.length" :key="`education_card_${bid.id}`" :bid="bid" />
		</div>
		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>

  import EducationProgramsCard from '@/components/parts/education/EducationProgramsCard'

  export default {
    name: 'EducationPrograms',
    components: { EducationProgramsCard },
    data() {
      return {}
    },
    watch: {},
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      isListener() {
        return this.user && this.user.role === 'listener'
      },
      bids() {
        return this.$store.getters['bids/items']
      },
    },
    mounted() {
      this.$store.dispatch('bids/fetchBidItems')
    },
    methods: {},
  }
</script>
