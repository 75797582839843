<template>
	<div>
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">

			<div class="d-flex flex-wrap flex-row align-items-end justify-content-between">
				<h1 class="page-header">Заказ №{{order.id}} <span class="ms-2 fs-14px fw-400" v-if="order.date_created">от {{order.date_created}}</span></h1>
			</div>

			<Manager :manager="manager" />
		</div>
	</div>
</template>

<script>
  import Manager from '@/components/parts/order/Manager'

  export default {
    name: 'OrderTitleCP',
    props: ['manager', 'order'],
    components: { Manager },
    data() {
      return {}
    },
    methods: {},
  }
</script>
