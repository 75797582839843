<template>
	<div>
		<div class="d-flex align-items-center mb-3 mt-md-0" v-if="manager">
			<span>Ваш менеджер:</span>
			<b-dropdown variant="link" right>
				<template #button-content>
					<span>{{[manager.surname, manager.name, manager.patronymic].join(' ')}}</span>
					<IconChevronDown class="ms-2 dropdown-chevron" />
				</template>
				<b-dropdown-text>
					<div class="d-flex mb-3 w-300px">
						<div class="w-35px h-35px rounded-pill overflow-hidden">
							<img src="/assets/img/user/user.svg" alt="" class="mw-100">
						</div>
						<div class="flex-1 ps-3">
							<p class="fw-400 mb-0">Ваш менеджер</p>
							<p class="mb-2 fw-600 fs-5">{{[manager.surname, manager.name, manager.patronymic].join(' ')}}</p>
							<p class="mb-1 fw-400" v-if="manager.phone"><a :href="'tel:' + manager.phone">{{$formatPhone(manager.phone)}}</a></p>
							<p class="mb-3 fw-400" v-if="manager.email"><a :href="'mailto:' + manager.email">{{manager.email}}</a></p>
							<!--<p class="mb-0">
								<a href="javascript:;" class="btn btn-primary">Написать в чат</a>
							</p>-->
						</div>
					</div>
				</b-dropdown-text>
			</b-dropdown>
		</div>
	</div>
</template>

<script>
  import IconChevronDown from '@/components/icons/IconChevronDown'

  export default {
    name: 'Manager',
    props: ['manager'],
    components: { IconChevronDown },
    data() {
      return {}
    },
    methods: {},
  }
</script>
