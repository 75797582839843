const appOptions = {
  api: 'https://erp-pish.dev17.ru/api',
  appSidebarMinified: false,
  appSidebarNone: false,
  appSidebarEnd: false,
  appSidebarTwo: false,
  appSidebarWide: false,
  appSidebarLight: false,
  appSidebarTransparent: false,
  appSidebarMobileToggled: false,
  appSidebarEndMobileToggled: false,
  appSidebarEndToggled: false,
  appSidebarEndCollapsed: false,
  appSidebarSearch: false,
  appContentFullHeight: false,
  appContentClass: false,
  appHeaderLanguageBar: false,
  appHeaderInverse: false,
  appHeaderMegaMenu: false,
  appHeaderMegaMenuMobileToggled: false,
  appTopMenu: false,
  appTopMenuMobileToggled: false,
  appEmpty: false,
  appBodyScrollTop: 0,
  suggestionToken: 'ff271456cd2e2f5f04f35aa21f08a7e446f69b9d',
}

export default appOptions;
