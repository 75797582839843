<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.514 4.74357C8.46239 4.68943 8.40032 4.64634 8.33157 4.61689C8.26281 4.58745 8.1888 4.57227 8.114 4.57227C8.0392 4.57227 7.96519 4.58745 7.89643 4.61689C7.82768 4.64634 7.76561 4.68943 7.714 4.74357L2 10.4576L2.8 11.2576L8.114 5.94357L13.4287 11.2576L14.2287 10.4576L8.514 4.74357Z" :fill="color" />
	</svg>
</template>

<script>
  export default {
    name: 'IconChevronDown',
	props: {
      'width': {
		type: Number,
		default: 16,
	  },
	  'height': {
        type: Number,
        default: 16,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#163300'
	  },
      'className': {
        type: String,
        default: ''
      }
    },
    data() {
      return {}
    },
  }
</script>
