import state from './bidsState'
import mutations from './bidsMutations'
import getters from './bidsGetters'
import actions from './bidsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
