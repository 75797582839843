<template>
	<div>
		<div class="d-flex flex-wrap flex-row align-items-start justify-content-between gap-4 mb-30px">

			<div class="d-flex flex-wrap flex-row align-items-end justify-content-between">
				<h1 class="page-header mb-0">Заявка №{{bid.id}} <span class="d-block fs-14px fw-400" v-if="bid.created_at">от {{bid.created_at}}</span></h1>
			</div>

			<span :class="'border-radius-xl px-12px py-7px ' + status(bid.status.code)" v-if="bid.status">Статус: {{bid.status.name}}</span>

			<div class="">
				<div class="d-flex gap-3">
					<span>Номер договора</span>
					<ChangeContractDataModal :id-order="idOrder" :id-bid="idBid" :number="bid.contract_number" :date="bid.contract_date">
						<template slot="button">
							<IconPen />
						</template>
					</ChangeContractDataModal>
				</div>
				<span v-if="bid.contract_number">{{bid.contract_number}} от {{bid.contract_date}}</span>
				<span v-else>-</span>
			</div>

			<a href="#" class="btn btn-outline-dark px-16px py-6px ms-auto">Перейти в чат</a>
		</div>
	</div>
</template>

<script>
  import Manager from '@/components/parts/order/Manager'
  import ChangeContractDataModal from '@/components/parts/order/modal/ChangeContractDataModal'
  import IconPen from '@/components/icons/IconPen'

  export default {
    name: 'OrderTitle',
    props: ['bid', 'idOrder', 'idBid'],
    components: { IconPen, ChangeContractDataModal, Manager },
    data() {
      return {}
    },
    methods: {
      status(code) {
        switch (code) {
          case 'payed':
            return 'bg-success text-dark'
          case 'waiting_auth_contact_face':
            return 'bg-warning text-dark'
          case 'waiting_verification_by_contact_person':
            return 'bg-warning text-dark'
          case 'waiting_auth_listeners':
            return 'bg-warning text-dark'
          case 'waiting_listeners_fill_profile':
            return 'bg-warning text-dark'
          case 'waiting_listener_consent_program':
            return 'bg-warning text-dark'
          case 'signing_contract':
            return 'bg-yellow text-dark'
          case 'waiting_payment':
            return 'bg-warning text-dark'
        }
      },
    },
  }
</script>
