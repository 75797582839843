<template>
	<div class="mw-900px">
		<div class="alert alert-success d-flex align-items-center mb-24px" v-if="user.personal_matter_documents_verified">
			<i class="fa fa-check me-12px"></i>
			Документы проверены
		</div>
		<router-link to="/profile/documents#matter_documents" class="btn btn-outline-success px-30px py-10px">Запрос на актуализацию данных</router-link>
		<div class="columns-2">
			<template v-for="field in items">
				<div class="columns-item mb-15px position-relative" :key="`documents_form_${field.type}`">
					<label class="form-label">{{field.label}}</label>
					<div class="border border-radius-sm py-8px px-16px d-flex align-items-center justify-content-between">
						<IconDocuments :width="18" :height="18" />
						<span class="ms-12px me-auto">{{field.name}}</span>
						<a class="text-decoration-underline" :href="field.link" :download="field.name">Скачать</a>
					</div>
				</div>
			</template>

		</div>
	</div>
</template>

<script>
  import InputFileField from '@/components/elements/InputFile'
  import IconDocuments from '@/components/icons/IconDocuments'

  export default {
    name: 'PersonalDataDocumentsView',
    components: { IconDocuments, InputFileField },
    data() {
      return {
        items: {},
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      isListener() {
        return this.user && this.user.role === 'listener'
      },
      documents() {
        return this.$store.getters['user/main_documents']
      },
      documentsTypes() {
        return this.$store.getters['dictionaries/items']('listener_personal_matter')
      },
    },
    watch: {
      documentsTypes(newVal, oldVal) {
        newVal.forEach(type => {
          this.documents.forEach(item => {
            if (type.code === item.type_code) {
              this.items[type.code] = {
                label: type.name,
                link: item.file_link,
                name: item.file_name,
                type: type.code,
              }
            }
          })
        })
      },
    },
    mounted() {
      this.$store.dispatch('dictionaries/fetchDictionary', { type: 'type_documents', subtype: 'listener_personal_matter' })
      this.$store.dispatch('user/fetchGetUserMainDocuments')
    },
    methods: {
    },
  }
</script>
