import state from './documentsState'
import mutations from './documentsMutations'
import getters from './documentsGetters'
import actions from './documentsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
