/**
 * init global directives
 */

import Vue from 'vue'

let bindings = [] // store binding data

function deleteBinding(el) {
  for (let i = 0; i < bindings.length; ++i) {
    if (bindings[i].el === el) {
      bindings.splice(i, 1)
      return true
    }
  }
  return false
}

function findBinding(el) {
  for (let i = 0; i < bindings.length; ++i) {
    if (bindings[i].el === el) {
      return bindings[i]
    }
  }
}

function getBinding(el) {
  let binding = findBinding(el)

  if (binding) {
    return binding
  }

  bindings.push(
    (binding = {
      el: el,
      binding: {},
    })
  )

  return binding
}

const inputHandler = function (e) {
  var binding = getBinding(this).binding
  var ch = String.fromCharCode(e.which)
  var re = new RegExp(binding.value)
  if (!ch.match(re)) {
    e.preventDefault()
  }
}

Vue.directive('filter', {
  bind: function (el, binding) {
    getBinding(el).binding = binding
    el.addEventListener('keypress', inputHandler)
  },
  unbind: function (el) {
    deleteBinding(el)
    el.removeEventListener('keypress', inputHandler)
  },
})
