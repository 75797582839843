<template>
	<div>
		<div class="mw-1200px">
			<!-- BEGIN page-header -->
			<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
				<h1 class="page-header">Мои данные</h1>
			</div>
			<!-- END page-header -->
			<div class="alert alert-success d-flex align-items-center mb-24px" v-if="user.profile_verified">
				<i class="fa fa-check me-12px"></i>
				Поля анкеты проверены
			</div>
			<ValidationObserver tag="form"
								@submit.prevent="sendForm"
								ref="observer-data-form"
								v-slot="{ invalid }"
								method="POST">


				<div class="row">
					<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required" name="Фамилия" mode="lazy" v-slot="{ errors }">
						<InputField
								:type-field="`text`"
								:errors-field="errors"
								:name-field="`Фамилия*`"
								:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
								:placeholder-field="`Введите фамилию`"
								v-model="form.surname"

						/>
					</ValidationProvider>

					<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required" name="Имя" mode="lazy" v-slot="{ errors }">
						<InputField
								:type-field="`text`"
								:errors-field="errors"
								:name-field="`Имя*`"
								:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
								:placeholder-field="`Введите имя`"
								v-model="form.name"

						/>
					</ValidationProvider>

					<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="" name="Отчество" mode="lazy" v-slot="{ errors }">
						<InputField
								:type-field="`text`"
								:errors-field="errors"
								:name-field="`Отчество`"
								:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
								:placeholder-field="`Введите отчество`"
								v-model="form.patronymic"

						/>
					</ValidationProvider>

					<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required|snils" name="СНИЛС" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-snils" class="form-label">СНИЛС*</label>

						<masked-input type="text"
									  class="form-control"
									  :class="errors.length ? 'is-invalid' : ''"
									  placeholder="___-___-___-__"
									  name="form-program-field-snils"
									  id="form-program-field-snils"
									  pattern="111-111-111-11"

									  v-model="form['snils']">

						</masked-input>

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-snils_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required|phone" name="Телефон" mode="lazy" v-slot="{ errors }">

						<label for="form-program-field-phone" class="form-label">Телефон*</label>

						<masked-input type="text"
									  class="form-control"
									  :class="errors.length ? 'is-invalid' : ''"
									  :placeholder="'+7 (999) 999-99-99'"
									  :name="'form-program-field-phone'"
									  id="form-program-field-phone"
									  :pattern="'+7 (111) 111-11-11'"

									  v-model="form['phone']">

						</masked-input>

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-program-field-phone_err'">{{err}}</span>
						</div>

					</ValidationProvider>

					<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required|email" name="Эл. адрес" mode="lazy" v-slot="{ errors }">
						<InputField
								:type-field="`text`"
								:errors-field="errors"
								:name-field="`Эл. адрес*`"
								:placeholder-field="`example@mail.ru`"
								:filter-field="`[0-9a-z@_\.]`"

								v-model="form.email"
						/>
					</ValidationProvider>
				</div>

				<button type="submit" class="btn btn-primary px-30px py-10px mt-15px" :disabled="!hasChanges">Отправить на проверку</button>

			</ValidationObserver>
		</div>
	</div>
</template>

<script>
  import axios from 'axios'
  import appOptions from '@/config/options'

  export default {
    name: 'CFQuestionnaire',
    data() {
      return {
        form: {
          surname: '',
          name: '',
          patronymic: '',
          phone: '',
          email: '',
          snils: '',
		  telegram: ''
        },
      }
    },
    watch: {},
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
      user() {
        return this.$store.getters['user/user']
      },
      personalData() {
        return JSON.parse(JSON.stringify(this.$store.getters['user/personal_data']))
      },
    },
    created() {
    },
    methods: {
      async submitForm() {
        const isValid = await this.$refs['observer-form'].validate()
        if (isValid) {
          let data = this.form

          axios({
            url: process.env.VUE_APP_API_URL + '',
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            },
            data: data,
          })
            .then(res => res.data)
            .then(res => {
              if (res) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: `Отправлено на проверку`,
                  type: 'success',
                })
              }
            })
        }

      },
    },
  }
</script>
