/*
 ORDERS LIST
 data |	Список найденных заказов |	Массив объектов
 data -> id |	ID заказа |	строка
 data -> name |	Название заказа |	строка
 data -> created_at |	Время создания заказа |	строка
 per_page |	Количество элементов на странице |	Число
 total |	Общее количество элементов |	Число

 DETAIL ORDER
 data |	Данные о заявке пользователя |	Объект
 data -> id |	ID заявки |	Число
 data -> user_id |	ID пользователя |	Число
 data -> price |	Цена заявки |	Число
 data -> manager_id |	ID менеджера |	Число
 data -> name |	Название заявки |	Строка
 data -> date_created |	Дата создания заявки |	Строка
 data -> manager |	Информация о менеджере |	Объект
 data -> manager->id |	ID менеджера |	Число
 data -> manager->surname |	Фамилия менеджера |	Строка
 data -> manager->name |	Имя менеджера |	Строка
 data -> manager->patronymic |	Отчество менеджера |	Строка
 data -> manager->email |	Электронная почта менеджера |	Строка
 data -> manager->phone |	Телефон менеджера |	Строка
 data -> payment |	Информация о платеже |	Объект
 data -> payment -> id |	ID платежа |	Число
 data -> payment ->type_face |	Тип лица (физическое или юридическое) |	Строка
 data -> payment ->order_id |	ID заказа |	Число
 data -> payment ->inn |	ИНН (Идентификационный номер налогоплательщика) |	Строка
 data -> payment ->kpp |	КПП (Код причины постановки на учет) |	Строка
 data -> payment ->ogrn |	ОГРН (Основной государственный регистрационный номер) |	Строка
 data -> payment ->city |	Город |	Строка
 data -> payment ->index |	Индекс |	Число
 data -> payment ->abbreviation |	Сокращение названия организации |	Строка
 data -> payment->full_ur_name |	Полное юридическое наименование |	Строка
 data -> payment-> fio_rod_head |	ФИО руководителя (в родительном падеже) |	Строка
 data -> payment->ur_address |	Юридический адрес |	Строка
 data -> payment->actual_address |	Фактический адрес |	Строка
 data -> payment->bik_bank |	БИК (Банковский идентификационный код) |	Строка
 data -> payment->name_bank |	Наименование банка |	Строка
 data -> payment->rc |	Расчетный счет |	Строка
 data -> payment->ks |	Корреспондентский счет |	Строка
 data -> payment->kbk |	КБК (Код бюджетной классификации) |	Строка
 data -> payment->personal_account |	Лицевой счет |	Строка
 data -> payment->fio_head |	ФИО руководителя организации |	Строка
 data -> payment->job_title |	Должность руководителя |	Строка
 data -> payment->acts_basis |	Основание для действий |	Строка
 data -> payment->concluded_accordance |	Основание для действий |	Строка
 data -> payment->surname |	Фамилия руководителя организации |	Строка
 data -> payment->name |	Имя руководителя организации |	Строка
 data -> payment->patronymic |	Отчество руководителя организации |	Строка
 data -> payment->snils |	СНИЛС (Страховой номер индивидуального лицевого счета) |	Строка
 data -> payment->registration_address |	Адрес регистрации |	Строка
 data -> payment->created_at |	Дата создания записи о платеже |	Строка (дата и время)
 data -> payment->updated_at |	Дата последнего обновления записи о платеже	Строка (дата и время)
 data -> bids |	Список заявок |	Массив объектов
 data -> bids-> id |	ID заявки |	Число
 data -> bids-> order_id |	ID заказа, связанного с заявкой |	Число
 data -> bids -> status_id |	ID статуса заявки |	Число
 data -> bids -> program_id |	ID программы, связанной с заявкой |	Число
 data -> bids -> created_at |	Дата создания заявки |	Строка (дата и время)
 data -> bids -> updated_at |	Дата последнего обновления заявки |	Строка (дата и время)
 data -> bids -> status |	Информация о статусе заявки |	Объект
 data -> bids -> status -> id |	ID статуса заявки |	Число
 data -> bids -> status -> name |	Название статуса заявки |	Строка
 data -> bids -> status -> code |	Код статуса заявки |	Строка
 data -> bids -> program |	Информация о программе, связанной с заявкой |	Объект
 data -> bids -> program -> id |	ID программы	 |Число
 data -> bids -> program -> name |	Название программы |	Строка
 data -> bids -> program -> price |	Цена программы |	Число
 data -> bids -> program -> type_document_id |	ID типа документа	Число
 data -> bids -> program -> count_clock |	Количество часов обучения	Число
 data -> bids -> listeners |	Список слушателей программы	Массив объектов
 data -> bids ->listeners -> id |	ID слушателя |	Число
 data -> bids ->listeners -> user_id |	ID пользователя |	Число
 data -> bids ->listeners -> info |	Информация о пользователе |	Объект
 data -> bids ->listeners -> info -> id |	ID пользователя |	Число
 data -> bids ->listeners -> info -> surname |	Фамилия пользователя |	Строка
 data -> bids ->listeners -> info -> name |	Имя пользователя |	Строка
 data -> bids ->listeners -> info -> patronymic |	Отчество пользователя |	Строка
 data -> bids ->listeners -> info -> email |	Электронная почта пользователя |	Строка
 data -> bids ->listeners -> info -> phone |	Телефон пользователя |	Строка
 data -> bids ->listeners -> configs |	Конфигурация слушателя программы |	Объект
 data -> bids ->listeners -> configs -> id |	ID конфигурации слушателя программы |	Число
 data -> bids ->listeners -> configs -> group_programm_id |	ID группы программы |	Число
 data -> bids ->listeners -> configs -> count_clock |	Количество часов обучения |	Число
 data -> bids ->listeners -> configs -> programm_type |	Тип программы (например, "ДО") |	Строка
 data -> bids ->listeners -> configs -> form_education |	Форма обучения (например, "Очно") |	Строка
 data -> bids ->listeners -> configs -> type_document |	Тип документа (например, "Удостоверение") |	Строка
 data -> bids ->listeners -> configs -> price |	Цена обучения |	Число
 data -> bids -> payment |	Информация о платеже по заявке |	Массив объектов
 data -> bids ->payment -> id |	ID платежа |	Число
 data -> bids ->payment -> document_id |	ID документа, связанного с платежом |	Число
 data -> bids ->payment -> payment_method_id |	ID метода платежа |	Число
 data -> bids ->payment -> percent |	Процент платежа (если есть) |	Число
 data -> loyalityProgram |	Информация о программе лояльности |	Объект
 data -> loyalityProgram -> id |	ID программы лояльности |	Число
 data -> loyalityProgram -> name |	Название программы лояльности |	Строка
 data -> loyalityProgram -> percent |	Процент скидки |	Число
 data -> loyalityProgram -> code |	Код программы лояльности |	Строка
 data -> priceDiscont |	Цена с учетом скидки |	Число
 * */

export default {
  items: [],
  pagination: {
    cur_ids: [],
    last_page: 1,
  }
}
