export default {
  RESET_LISTENERS(state) {
    state = {
      items: []
    }
  },
  SET_LISTENERS_ITEM(state, payload) {
    /*state.items[payload.id] = {
     ...state.items[payload.id],
     ...payload,
     }*/
  },
  SET_LISTENERS_ITEMS(state, payload) {
    state.items = payload.items.map(item => ({ ...item, idOrder: Number(payload.idOrder) }))
  },
  REMOVE_LISTENER_ITEM(state, payload) {
    state.items = state.items.filter(item => item.id !== payload)
  },
}
