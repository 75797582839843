<template>
	<div>
		<ContactPersonOrderList :items="items" v-if="isContactPerson" />
		<MopRopOrderList :items="items" v-if="isMop || isRop">
			<template slot="filter">
				<form class="mw-1200px" v-if="isMop || isRop" @submit.prevent="sendFilter">
					<div class="d-flex flex-wrap gap-3">
						<div class="w-100 w-sm-270px ">
							<InputField
									:type-field="`text`"
									:name-field="`Наименование контрагента`"
									:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
									v-model="filter.filter_contr_agent_name"
							/>
						</div>
						<div class="w-100 w-sm-150px">
							<InputField
									:type-field="`date`"
									:name-field="`Дата заказа`"
									:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
									v-model="filter.filter_date_created">
								<template slot="icon">
									<span class="pointer-event-none position-absolute end-0 top-0 w-40px h-40px d-flex align-items-center justify-content-center"><i class="fa fa-calendar"></i></span>
								</template>
							</InputField>
						</div>
						<div class="w-100 w-sm-200px ">
							<InputField
									:type-field="`text`"
									:name-field="`ИНН`"
									:filter-field="`[0-9]`"
									:max-length="12"
									v-model="filter.filter_inn"
							/>
						</div>
						<div class="w-100 w-sm-200px ">
							<InputField
									:type-field="`text`"
									:name-field="`КПП`"
									:filter-field="`[0-9]`"
									:max-length="9"
									v-model="filter.filter_kpp"
							/>
						</div>
						<div class="w-100 w-sm-150px ">
							<InputField
									:type-field="`text`"
									:name-field="`Сумма договора`"
									:filter-field="`[0-9]`"
									v-model="filter.filter_contract_sum"
							/>
						</div>
						<div class="w-100 w-sm-150px ">
							<InputField
									:type-field="`text`"
									:name-field="`Номер договора`"
									:filter-field="`[0-9а-яА-ЯёЁa-zA-Z\-]`"
									v-model="filter.filter_contract_number"
							/>
						</div>
						<div class="w-100 w-sm-270px" v-if="advanced_filter">
							<label class="form-label">Тип ЮЛ</label>

							<v-select :options="typeFaceList" :reduce="item => item.code" label="label" :clearable="false" :searchable="false" v-model="filter.filter_type_face"></v-select>
						</div>
						<div class="w-100 w-sm-150px " v-if="advanced_filter">
							<InputField
									:type-field="`text`"
									:name-field="`Сумма заказа`"
									:filter-field="`[0-9]`"
									v-model="filter.filter_order_sum"
							/>
						</div>
					</div>
					<div class="d-flex flex-wrap mb-5 mt-24px gap-3">
						<button type="submit" class="btn btn-primary px-16px py-8px">Поиск</button>
						<button type="button" class="btn btn-outline-primary px-16px py-8px" @click="advanced_filter = true" v-if="!advanced_filter">Расширенный фильтр</button>
						<button type="button" class="btn btn-gray-100 text-dark px-16px py-8px" @click="resetFilter">Сбросить</button>
					</div>
				</form>

				<div class="d-flex flex-wrap gap-3 align-items-center mb-24px mw-1200px">
					<!--<label for="table_checkbox_filter_payment_soon" :class="`btn px-16px py-8px ${filter.filter_payment_soon ? 'btn-gray-600' : 'btn-outline-gray-600'}`">
						<input type="checkbox"
							   v-model="filter.filter_payment_soon"
							   id="table_checkbox_filter_payment_soon"
							   @change="sendFilter"
							   class="d-none">
						<span>Скоро оплата</span>
					</label>
					<label for="table_checkbox_filter_payment_overdue" :class="`btn me-auto px-16px py-8px ${filter.filter_payment_overdue ? 'btn-gray-600' : 'btn-outline-gray-600'}`">
						<input type="checkbox"
							   v-model="filter.filter_payment_overdue"
							   id="table_checkbox_filter_payment_overdue"
							   @change="sendFilter"
							   class="d-none">
						<span>Оплата просрочена</span>
					</label>-->
					<!--<router-link to="``" class="btn btn-success px-16px py-8px ms-auto">Добавить заявку</router-link>-->
					<button type="button" class="btn btn-primary px-16px py-8px" @click="exportCSV">Экспорт в CSV</button>
				</div>
			</template>
		</MopRopOrderList>
		<span v-if="!loading && !items.length">Заказы не найдены</span>

		<ul class="pagination mt-4" v-if="pages.length > 1">
			<div class="page-item" :class="page <= 1 ? 'disabled' : ''">
				<router-link :to="'/orders/?page=' + (page - 1)" class="page-link">«</router-link>
			</div>
			<div class="page-item" v-for="item in pages" :key="item + '_page'">
				<router-link :to="'/orders/?page=' + item" class="page-link" :class="page.toString() === item.toString() ? 'active' : ''">{{item}}</router-link>
			</div>
			<div class="page-item" :class="page >= last_page ? 'disabled' : ''">
				<router-link :to="'/orders/?page=' + (page + 1)" class="page-link">»</router-link>
			</div>
		</ul>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>

		<NeedProfileMessage />
	</div>
</template>

<script>
  import NeedProfileMessage from '@/components/elements/NeedProfileMessage'
  import ContactPersonOrderList from '@/components/parts/order/ContactPersonOrderList'
  import MopRopOrderList from '@/components/parts/order/MopRopOrderList'
  import InputField from '@/components/elements/Input'
  import axios from 'axios'

  const FILTER_FIELDS = {
    filter_contr_agent_name: '',
    filter_date_created: '',
    filter_inn: '',
    filter_kpp: '',
    filter_contract_sum: '',
    filter_contract_number: '',
    filter_type_face: null,
    filter_order_sum: '',
    filter_payment_soon: null,
    filter_payment_overdue: null,
  }

  export default {
    name: 'OrdersList',
    components: { InputField, MopRopOrderList, ContactPersonOrderList, NeedProfileMessage },

    data() {
      return {
        typeFaceList: [{ code: 'UR', label: 'Юридическое лицо' }, { code: 'FIZ', label: 'Физическое лицо' }, { code: 'IP', label: 'ИП' }],
        page: 1,
        items: [],
        filter: JSON.parse(JSON.stringify(FILTER_FIELDS)),
        advanced_filter: false,
		loading: false,
      }
    },

    computed: {
      itemsGetter() {
        return JSON.parse(JSON.stringify(this.$store.getters['orders/itemsByPage']))
      },
      user() {
        return this.$store.getters['user/user']
      },
      isListener() {
        return this.user && this.user.role === 'listener'
      },
      isContactPerson() {
        return this.user && this.user.role === 'contact_person'
      },
      isMop() {
        return this.user && this.user.role === 'sales_department_manager'
      },
      isRop() {
        return this.user && this.user.role === 'head_department_manager'
      },
      last_page() {
        return this.$store.getters['orders/last_page']
      },
      pages() {
        let pages = []
        for (let i = 1, l = this.last_page; i <= l; i++) {
          pages.push(i)
        }
        return pages
      },
    },

    watch: {
      $route(to, from) {
        if (to && from && to.query?.page !== from.query?.page) {
          this.page = to.query?.page
          this.getList({ page: this.page })
        }
      },

      itemsGetter(newVal, oldVal) {
        this.items = newVal.map(item => ({ ...item, checked: false }))
      },
    },

    mounted() {
      if (this.user && !this.user.need_edit_profile) {
        this.page = this.$route.query?.page || 1
        this.getList({ page: this.page })
      }
    },

    methods: {

      getList(params) {
        this.loading = true
        this.$store.dispatch('orders/fetchOrderItems', params).then(res => {
          this.loading = false
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout')
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
      },

      sendFilter() {
        this.page = 1
        this.getList({ page: this.page, filter: this.removeEmptyValues(JSON.parse(JSON.stringify(this.filter))) })
      },

      removeEmptyValues(object) {
        return typeof (!!object && object === 'object' && !Array.isArray(object))
          ? Object.fromEntries(Object.entries(object).filter(([_, value]) => value))
          : object
      },

      resetFilter() {
        this.page = 1
        this.filter = JSON.parse(JSON.stringify(FILTER_FIELDS))
        this.getList({ page: this.page })
      },

      exportCSV() {
        let checkedItems = [...this.items.filter(item => item.checked)]
		if (!checkedItems.length) {
          this.$notify({
            group: 'center',
            title: ``,
            text: `Выберите заказы`,
            type: 'error',
          })
		} else {
          axios({
            url: process.env.VUE_APP_API_URL + `/orders/`,
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            },
            data: {
              ids: checkedItems.map(item => item.id)
            },
          })
            .then(res => res.data)
            .then(res => {
              if (res.data) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: res.data?.message,
                  type: 'success',
                })
              }
            })
		}
      },

    },
  }
</script>
