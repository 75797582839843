<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M22.2868 11.1428H12.8578V1.71484H11.1438V11.1428H1.71484V12.8578H11.1438V22.2858H12.8578V12.8578H22.2868V11.1428Z" :fill="color"/>
	</svg>
</template>

<script>
  export default {
    name: 'IconPlus',
	props: {
      'width': {
		type: Number,
		default: 24,
	  },
	  'height': {
        type: Number,
        default: 24,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#787A85'
	  },
	  'className': {
        type: String,
		default: ''
	  }
	},
    data() {
      return {}
    },
  }
</script>
