<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'ChangeContractDataModal' + idBid" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Изменить номер договора</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('ChangeContractDataModal' + idBid)"></a>
			</template>

			<ValidationObserver tag="form"
								@submit.prevent="submitForm"
								ref="observer-form"
								v-slot="{ invalid }"
								method="POST">

				<div class="row">
					<ValidationProvider
							tag="div"
							class="col-12 col-sm-6 mb-15px position-relative"
							rules="required"
							name="Номер договора"
							mode="lazy"
							v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:errors-field="errors"
							:name-field="`Номер договора*`"
							:filter-field="`[0-9]`"
							:placeholder-field="`Номер договора`"
							v-model="form.contract_number"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-12 col-sm-6 mb-15px position-relative"
						rules="required"
						name="Дата договора"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`date`"
							:errors-field="errors"
							:name-field="`Дата договора*`"
							v-model="form.contract_date"
					/>
				</ValidationProvider>
				</div>

			</ValidationObserver>


			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
				<button type="button" @click="submitForm" class="btn btn-primary btn-lg">
					Отправить
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  import axios from 'axios'
  import InputField from '@/components/elements/Input'

  export default {
    name: 'ChangeContractDataModal',
    components: { InputField },
    props: ['button', 'idOrder', 'idBid', 'number', 'date'],
    data() {
      return {
        files: [],
        selectedType: null,
        form: {
          contract_number: '',
          contract_date: '',
        },
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },

      token() {
        return this.$store.getters['auth/token']
      },
    },
    watch: {
      number(newVal) {
        this.form.contract_number = newVal
      },
      date(newVal) {
        this.form.contract_date = newVal
      },
    },
    methods: {

      showModal() {
        this.$bvModal.show('ChangeContractDataModal' + this.idBid)
      },

      async submitForm() {
        const isValid = await this.$refs['observer-form'].validate()

        if (!isValid) return false

        let formData = new FormData()

        formData.append('contract_number', this.form.contract_number)
        formData.append('contract_date', this.form.contract_date)

        let path = ''
        switch (this.user.role) {
          case 'sales_department_manager':
            path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/contract_number/set`
            break
          case 'head_department_manager':
            path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/contract_number/set`
            break
        }
        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: formData,
        })
          .then(res => res.data)
          .then(res => {
            if (!!res.success && res.success === false) {
              this.$notify({
                group: 'center',
                title: `Ошибка!`,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Данные успешно сохранены!`,
                type: 'error',
              })
              this.$bvModal.hide('ChangeContractDataModal' + this.idBid)
			  this.$store.dispatch('bids/setBidItem', res)
            }
          })
      },
    },
  }
</script>

