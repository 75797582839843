<template>
	<div>
		<!-- BEGIN breadcrumb -->
		<ol class="breadcrumb d-none d-md-flex">
			<li class="breadcrumb-item">
				<router-link to="/">Главная</router-link>
			</li>
			<li class="breadcrumb-item">
				<router-link to="/">Личный кабинет</router-link>
			</li>
			<li class="breadcrumb-item active">Профиль</li>
		</ol>
		<!-- END breadcrumb -->

		<!-- START form profile -->
		<ListenerQuestionnaire v-if="isListener" />
		<CFQuestionnaire v-else />
		<!-- END form profile -->

		<!-- START page notifications -->
		<notifications group="center" position="top center" :duration="10000" >
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
		<!-- END page notifications -->
	</div>
</template>

<script>
  import CFQuestionnaire from '@/components/parts/profile/CFQuestionnaire'
  import ListenerQuestionnaire from '@/components/parts/profile/ListenerQuestionnaire'
  export default {
    name: 'Profile',
    components: { ListenerQuestionnaire, CFQuestionnaire },
    data() {
      return {
	  }
    },
	watch: {
	},
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
	  isListener() {
        return this.user && this.user.role === 'listener'
	  },
    },
    mounted() {
    },
    methods: {
    },
  }
</script>
