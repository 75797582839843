<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'AddMotivationLetterModal' + idProgram" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Правила предоставления мотивационного письма</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('AddMotivationLetterModal' + idProgram)"></a>
			</template>

			<div class="">Текстовый блок</div>

			<div class="mb-3px text-dark mt-24px">Прикрепить файл</div>
			<InputFile @setFiles="setFiles" />


			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
				<button type="button" @click="submitForm" class="btn btn-primary btn-lg">
					Отправить
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  import IconTrash from '@/components/icons/IconTrash'
  import InputFile from '@/components/elements/InputFile'

  export default {
    name: 'AddMotivationLetterModal',
    components: { InputFile, IconTrash },
    props: ['button', 'idProgram'],
    data() {
      return {
        files: [],
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
    },
    methods: {
      setFiles(files) {
        this.files = files
      },

      showModal() {
        this.$bvModal.show('AddMotivationLetterModal' + this.idProgram)
      },

      submitForm() {
        if (!this.files.length) {
          this.$notify({
            group: 'center',
            title: `Ошибка!`,
            text: 'Прикрепите файл',
            type: 'error',
          })
		  return
        }

		this.$emit('setFile', this.files[0])
        this.$bvModal.hide('AddMotivationLetterModal' + this.idProgram)
      },
    },
  }
</script>

