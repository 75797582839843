/**
 * Format phone number from 79999999999 to +7 (999) 999-99-99
 */
export default {
  methods: {
    $formatPrice(price, decimal = 0) {
      return price ? Number(price).toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") : ''
    },
  },
}
