/**
 * import and init global mixins
 */

import Vue from 'vue'

import jumpTo from '@/mixins/jumpTo'
import formatDateTime from '@/mixins/formatDateTime'
import formatPhone from '@/mixins/formatPhone'
import formatPrice from '@/mixins/formatPrice'
import withoutWatchers from '@/mixins/withoutWatchers'
import plural from '@/mixins/plural'

Vue.mixin(jumpTo)
Vue.mixin(formatDateTime)
Vue.mixin(formatPhone)
Vue.mixin(formatPrice)
Vue.mixin(withoutWatchers)
Vue.mixin(plural)
