export default {
  items: state => idOrder => {
    if (state.items.length) {
      return state.items.filter(item => Number(idOrder) === Number(item.idOrder))
    }
    return []
  },
  itemById: state => id => {
    for (let key in state.items) {
      if (state.items.hasOwnProperty(key) && Number(state.items[key].id) === Number(id)) {
        return state.items[key]
      }
    }
    return null
  },
}
