<template>
	<div>
	</div>
</template>

<script>
  export default {
    name: 'home',
    data() {
      return {}
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      role() {
        return this.user ? this.user.role : null
      },
    },
    mounted() {
      if (this.role) {
        switch (this.role) {
          case 'listener':
            if (this.user.need_edit_profile && !this.user.need_edit_bid_confirm) {
              this.$router.replace({ name: 'Profile' })
			} else if (this.user.need_edit_personal_matter_documents && !this.user.need_edit_bid_confirm && !this.user.need_edit_profile) {
              this.$router.replace({ name: 'DocumentsEdit' })
            } else {
              this.$router.replace({ name: 'Education' })
			}
            break
          case 'contact_person':
          case 'head_department_manager':
          case 'sales_department_manager':
            this.$router.replace({ name: 'OrdersList' })
            break
        }
      }
    },
    methods: {},
  }
</script>
