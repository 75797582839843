<template>
    <span>
		<span @click="showModal()">
			<slot name="button"></slot>
		</span>

		<b-modal :id="'modalPayerDetails'" size="lg" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">{{data ? 'Редактировать' : 'Добавить'}} данные плательщика</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('modalPayerDetails')"></a>
			</template>

			<div class="d-flex">
			  <button
					  class="me-20px"
					  :class="'btn' + (type_face === type.type ? ' btn-primary' : '  btn-outline-primary')"
					  v-for="type in types"
					  :key="type.type"
					  @click="type_face = type.type">{{type.name}}</button>
			</div>

			<PayerForm v-show="type_face === 'fiz'" :data="data" :fields-map="formFields.fiz.map" :fields-form="formFields.fiz.form" :id-bid="idBid" :id-order="idOrder" @closeModal="$bvModal.hide('modalPayerDetails')" />
			<PayerForm v-show="type_face === 'ur'" :data="data" :fields-map="formFields.legal.map" :fields-form="formFields.legal.form" :id-bid="idBid" :id-order="idOrder" @closeModal="$bvModal.hide('modalPayerDetails')" />
			<PayerForm v-show="type_face === 'ip'" :data="data" :fields-map="formFields.ip.map" :fields-form="formFields.ip.form" :id-bid="idBid" :id-order="idOrder" @closeModal="$bvModal.hide('modalPayerDetails')" />

			<div slot="modal-footer"></div>
		</b-modal>
    </span>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import PayerForm from '@/components/parts/order/form/PayerForm'
  import formFields from '@/config/forms/payer'

  export default {
    name: 'PayerDetailsEditModal',
    components: { PayerForm },
    props: ['button', 'data', 'idOrder', 'idBid'],
    data() {
      return {
        formFields: formFields,
        type_face: 'ur',
        types: [
          {
            type: 'ur',
            name: 'Юридическое лицо',
          },
          {
            type: 'fiz',
            name: 'Физическое лицо',
          },
          {
            type: 'ip',
            name: 'Индивидуальный предприниматель',
          },
        ],
      }
    },
    computed: {
      dataItems() {
        return this.data
	  }
    },
    watch: {
      dataItems(newVal, oldVal) {
        console.log('new', newVal)
        this.type_face = newVal.type_face
	  }
    },
    mounted() {
      if (this.data) {
        this.type_face = this.data.type_face
      }
    },
    destroyed() {
    },
    methods: {
      showModal() {
        this.$bvModal.show('modalPayerDetails')
      },
    },
  }
</script>
