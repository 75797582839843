export default {
  itemById: state => id => {
    for (let key in state.items) {
      if (state.items.hasOwnProperty(key) && Number(state.items[key].id) === Number(id)) {
        return state.items[key]
      }
    }
    return null
  },
  items: state => state.items,
}
