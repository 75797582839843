<template>
	<div class="menu">
		<div class="menu-profile">
			<div class="menu-profile-link">
				<!--<div class="menu-profile-cover with-shadow"></div>-->
				<div class="menu-profile-image">
					<img :src="LogoUser" alt="" />
				</div>
				<div class="menu-profile-info" v-if="user">
					<div class="d-flex align-items-center">
						<div class="flex-grow-1">
							{{[user.name, user.patronymic, user.surname].join(' ')}}
						</div>
					</div>
					<small>{{role}}</small>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import AppOptions from '@/config/options.js'
  import LogoUser from '../../../public/assets/img/user/user.svg'

  export default {
    name: 'SidebarNavProfile',
    data() {
      return {
        LogoUser: LogoUser,
        stat: '',
        appOptions: AppOptions,
      }
    },
	computed: {
      user() {
        return this.$store.getters['user/user']
      },
	  role(){
        if (this.user && this.user.role === 'contact_person') {
          return 'Контактное лицо'
		} else if (this.user && this.user.role === 'listener') {
          return 'Слушатель'
		} else if (this.user && this.user.role === 'sales_department_manager') {
          return 'Менеджер отдела продаж'
        } else if (this.user && this.user.role === 'head_department_manager') {
          return 'Руководитель отдела продаж'
        }
        return ''
	  }
	},
    methods: {},
  }
</script>
