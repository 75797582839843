import axios from 'axios'
import appOptions from '@/config/options'

export default {
  async fetchListenerItem({ rootState, commit }, id) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/listener/' + id,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data ? commit('SET_LISTENERS_ITEM', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      //console.log('ORDER ITEM', res)
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async fetchListenerItems({ rootState, commit }, params) {
    try {
      let path = ''
      switch (rootState.user.user.role) {
        case 'contact_person':
          path = `/contact_person/orders/${params.idOrder}/listeners/list`
          break
        case 'sales_department_manager':
          path = `/mop/orders/${params.idOrder}/listeners/list`
          break
        case 'head_department_manager':
          path = `/rop/orders/${params.idOrder}/listeners/list`
          break
      }

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data ? commit('SET_LISTENERS_ITEMS', {items: res.data.data, idOrder: params.idOrder}) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      //console.log('ORDER ITEM', res)
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  async removeListenerItem({ rootState, commit }, id) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + '/listener/delete',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          id: id
        }
      })
      return await res.data ? commit('REMOVE_LISTENER_ITEM', id) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return  err
    }
  },
  resetListeners({ rootState, commit }) {
    return commit('RESET_LISTENERS')
  }
}
