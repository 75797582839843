<template>
	<div>
		<div class="mt-25px mb-25px">
			<a href="javascript:void(0)" @click="hasHistory() ? $router.go(-1) : $router.push('/')" class="d-inline-flex align-items-center text-decoration-none text-dark">
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
					<path d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z" fill="#2B2A29" />
				</svg>
				Назад
			</a>
		</div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Добавить программу</h1>
		</div>
		<!-- END page-header -->
		<div class="mw-1200px">

			<label class="fs-14px mb-8px" for="search-program">Необходимо выбрать интересующую Вас программу</label>
			<form class="input-group mw-900px position-relative" @submit.prevent="getSearchedList">
				<input type="text" id="search-program" class="form-control h-40px z-1" v-model="searchProgram">
				<button type="submit" class="btn btn-primary">
					<i class="fa fa-search"></i>
				</button>
				<a href="javascript:void(0)" class="w-40px h-40px d-flex align-items-center justify-content-center text-decoration-none position-absolute top-0 end-0 me-5 z-3" v-if="searchProgram && searchedListProgram.length" @click="clearSearch">
					<i class="fa fa-times"></i>
				</a>
			</form>

			<div class="table-responsive-md mt-24px" v-if="searchedListProgram.length || selectedProgram">
				<table class="table mb-0 align-middle">
					<thead>
					<tr>
						<th>Наменование программы</th>
						<th>Объем часов</th>
						<!--<th>Вид прогаммы</th>-->
						<th>Форма обучения</th>
						<!--<th>Вид документа</th>-->
						<th>Стоимость</th>
						<th class="w-100px"></th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="item in searchedListProgram" :key="'program_' + item.id" :class="selectedProgram && item.program_id === selectedProgram.program_id ? 'selected' : ''" v-if="searchedListProgram.length && item.program_id !== selectedProgram?.program_id">
						<td>
							{{item.program_name}}
						</td>
						<td>
							{{item.hours_volume}}
						</td>
						<!--<td>
							{{item.type_educational_program ? item.type_educational_program.name : ''}}
						</td>-->
						<td>
							{{item.study_form ? item.study_form.name : ''}}
						</td>
						<!--<td>
							{{item.type_education_document ? item.type_education_document.name : ''}}
						</td>-->
						<td>
							{{$formatPrice(item.price)}}
						</td>
						<td nowrap="nowrap">
							<a href="javascript:;" class="btn btn-sm btn-primary ms-12px py-5px px-7px w-100px" @click="selectProgram(item)">Выбрать</a>
						</td>
					</tr>
					<template v-if="selectedProgram">
						<tr>
							<td colspan="7" class="bg-gray-10 px-16px py-8px">Выбранная программа</td>
						</tr>
						<tr class="selected">
							<td>
								{{selectedProgram.program_name}}
							</td>
							<td>
								{{selectedProgram.hours_volume}}
							</td>
							<!--<td>
								{{selectedProgram.type_educational_program ? selectedProgram.type_educational_program.name : ''}}
							</td>-->
							<td>
								{{selectedProgram.study_form ? selectedProgram.study_form.name : ''}}
							</td>
							<!--<td>
								{{selectedProgram.type_education_document ? selectedProgram.type_education_document.name : ''}}
							</td>-->
							<td>
								{{$formatPrice(selectedProgram.price)}}
							</td>
							<td nowrap="nowrap">
								<span class="btn btn-sm btn-gray ms-12px py-5px px-7px w-100px">Выбрано</span>
							</td>
						</tr>
					</template>
					</tbody>
				</table>
			</div>

			<div class="fs-22px fw-500 mt-24px">Добавьте слушателя</div>

			<div class="d-flex flex-wrap mt-16px">
				<a href="javascript:void(0)" @click="addNewListener" class="d-inline-flex align-items-center fw-500 mt-32px text-decoration-none text-dark">
					<span class="d-flex align-items-center justify-content-center w-50px h-50px border-radius-round bg-gray-back me-12px">
						<IconPlus />
					</span>
					Добавить нового слушателя
				</a>
				<AddListenerModalAddProgram :id-order="idOrder" @addListeners="handleAddListeners">
					<template slot="button">
						<span class="h-50px border mx-24px border-end border-light"></span>
						<span class="d-inline-flex align-items-center fw-500 mt-32px text-decoration-none text-dark">
							<span class="d-flex align-items-center justify-content-center w-50px h-50px border-radius-round bg-gray-back me-12px">
								<IconPlus />
							</span>
							Добавить слушателя из списка
						</span>
					</template>
				</AddListenerModalAddProgram>
			</div>

			<ValidationObserver tag="form"
								@submit.prevent="submitForm"
								ref="observer-form-listener"
								v-slot="{ invalid }"
								method="POST">
				<template v-if="selectedListeners.length">
					<div v-for="(form, index) in selectedListeners" :key="'form_listener_' + index"
						 class="border border-2 border-radius-lg border-gray px-24px pt-24px pb-18px mt-24px mw-900px position-relative">

						<a href="javascript:void(0)" @click.prevent="removeListener(index)" class="text-decoration-none position-absolute top-0 end-0 mt-5px me-5px p-15px text-red z-2">
							<i class="fa fa-times"></i>
						</a>

						<div class="row">
							<ValidationProvider ref="form-program-avalible_vo_spo" tag="div" class="col-12 mb-15px position-relative" name="Наличие ВПО/СПО" v-slot="{ errors }">

								<div :class="'form-check' + (errors.length ? ' is-invalid' : '')">
									<input type="checkbox"
										   :disabled="form.avalible_vo_spo_disabled"
										   v-model="form['avalible_vo_spo']"
										   id="form-program-field-avalible_vo_spo"
										   class="form-check-input" />
									<label for="form-program-field-avalible_vo_spo" class="form-label">Есть высшее или среднее профессиональное образование / Сейчас получаю</label>
								</div>
								<div v-if="errors.length" class="invalid-tooltip">
									<span v-for="err in errors" :key="'form-program-field-avalible_vo_spo_err'">{{err}}</span>
								</div>

							</ValidationProvider>

							<ValidationProvider ref="form-program-surname" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Фамилия" mode="lazy" v-slot="{ errors }">

								<label for="form-program-field-surname" class="form-label">Фамилия*</label>

								<input type="text"
									   class="form-control"
									   :disabled="form.surname_disabled"
									   :class="errors.length ? 'is-invalid' : ''"
									   placeholder="Введите фамилию"
									   id="form-program-field-surname"
									   v-model="form['surname']" />

								<div v-if="errors.length" class="invalid-tooltip">
									<span v-for="err in errors" :key="'form-program-field-surname_err'">{{err}}</span>
								</div>

							</ValidationProvider>

							<ValidationProvider ref="form-program-name" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Имя" mode="lazy" v-slot="{ errors }">

								<label for="form-program-field-name" class="form-label">Имя*</label>

								<input type="text"
									   class="form-control"
									   :class="errors.length ? 'is-invalid' : ''"
									   placeholder="Введите имя"
									   :disabled="form.name_disabled"
									   id="form-program-field-name"
									   v-model="form['name']" />

								<div v-if="errors.length" class="invalid-tooltip">
									<span v-for="err in errors" :key="'form-program-field-name_err'">{{err}}</span>
								</div>

							</ValidationProvider>

							<ValidationProvider ref="form-program-patronymic" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required" name="Отчество" mode="lazy" v-slot="{ errors }">

								<label for="form-program-field-patronymic" class="form-label">Отчество*</label>

								<input type="text"
									   class="form-control"
									   :class="errors.length ? 'is-invalid' : ''"
									   :disabled="form.patronymic_disabled"
									   placeholder="Введите отчество"
									   id="form-program-field-patronymic"
									   v-model="form['patronymic']" />

								<div v-if="errors.length" class="invalid-tooltip">
									<span v-for="err in errors" :key="'form-program-field-patronymic_err'">{{err}}</span>
								</div>

							</ValidationProvider>

							<ValidationProvider ref="form-program-snils" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|snils" name="СНИЛС" mode="lazy" v-slot="{ errors }">

								<label for="form-program-field-snils" class="form-label">СНИЛС*</label>

								<masked-input type="text"
											  class="form-control"
											  :class="errors.length ? 'is-invalid' : ''"
											  :disabled="form.snils_disabled"
											  placeholder="___-___-___-__"
											  name="form-program-field-snils"
											  id="form-program-field-snils"
											  pattern="111-111-111-11"
											  v-model="form['snils']">

								</masked-input>

								<div v-if="errors.length" class="invalid-tooltip">
									<span v-for="err in errors" :key="'form-program-field-snils_err'">{{err}}</span>
								</div>

							</ValidationProvider>

							<ValidationProvider ref="form-program-email" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|email" name="Эл. адрес" mode="lazy" v-slot="{ errors }">

								<label for="form-program-field-email" class="form-label">Эл. адрес*</label>

								<input type="text"
									   class="form-control"
									   :class="errors.length ? 'is-invalid' : ''"
									   :disabled="form.email_disabled"
									   :placeholder="'example@mail.ru'"
									   :name="'form-program-field-email'"
									   id="form-program-field-email"
									   v-model="form['email']" />

								<div v-if="errors.length" class="invalid-tooltip">
									<span v-for="err in errors" :key="'form-program-field-email_err'">{{err}}</span>
								</div>

							</ValidationProvider>

							<ValidationProvider ref="form-program-phone" tag="div" class="col-12 col-sm-4 mb-15px position-relative" rules="required|phone" name="Телефон" mode="lazy" v-slot="{ errors }">

								<label for="form-program-field-phone" class="form-label">Телефон*</label>

								<masked-input type="text"
											  class="form-control"
											  :class="errors.length ? 'is-invalid' : ''"
											  :placeholder="'+7 (999) 999-99-99'"
											  :disabled="form.phone_disabled"
											  :name="'form-program-field-phone'"
											  id="form-program-field-phone"
											  :pattern="'+7 (111) 111-11-11'"
											  v-model="form['phone']">

								</masked-input>

								<div v-if="errors.length" class="invalid-tooltip">
									<span v-for="err in errors" :key="'form-program-field-phone_err'">{{err}}</span>
								</div>

							</ValidationProvider>
						</div>

						<template v-if="form.group_program.length">
							<div class="fs-18px fw-600 mb-10px mt-10px">Состав программы:</div>
							<div class="d-flex flex-wrap gap-5">
								<div v-for="block in form.group_program" :key="`block_${block.id}`" v-if="block.blocks.length">
									<div class="form-check fs-16px fw-500 mb-12px">
										<input type="checkbox" v-model="block.checked" :disabled="block.type === 'mandatory'" class="form-check-input form-check-input-bordered" />
										<label class="form-label">{{block.name}}</label>
									</div>
									<div v-for="item in block.blocks" :key="`block_${block.id}_${item.id}`" class="mb-8px">
										- {{item.name}}
									</div>
								</div>
							</div>
							<div class="fs-18px fw-600 mb-8px mt-16px">Стоимость:</div>
							<div class="fs-16px">{{form.price ? $formatPrice(form.price) : 0}} Р</div>
						</template>

					</div>
				</template>

				<button type="submit" class="btn btn-primary px-30px py-10px mt-30px w-200px">Сохранить</button>

			</ValidationObserver>
		</div>
		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>

		<NeedProfileMessage />
	</div>
</template>

<script>
  import uniqid from 'uniqid'
  import axios from 'axios'
  import IconPlus from '@/components/icons/IconPlus'
  import AddListenerModalAddProgram from '@/components/parts/order/modal/AddListenerInAddProgramModal'
  import NeedProfileMessage from '@/components/elements/NeedProfileMessage'

  const LISTENER_FORM = {
    avalible_vo_spo: false,
    surname: '',
    name: '',
    patronymic: '',
    phone: '',
    email: '',
    snils: '',
    group_program: [],
    price: 0,
  }

  export default {
    name: 'OrderAddListener',
    components: { NeedProfileMessage, AddListenerModalAddProgram, IconPlus },
    data() {
      return {
        searchProgram: '',
        searchedListProgram: [],
        selectedProgram: null,
        selectedListeners: [],
      }
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
      idOrder() {
        return this.$route.params?.id
      },
      selectedListenersForWatch() {
        return JSON.parse(JSON.stringify(this.selectedListeners))
      },
      selectedProgramForWatch() {
        return JSON.parse(JSON.stringify(this.selectedProgram))
      },
      user() {
        return this.$store.getters['user/user']
      },
    },
    watch: {
      selectedProgramForWatch(newVal, oldVal) {
        this.setProgramBlocksToForm()
      },
      selectedListenersForWatch(newVal, oldVal) {
        this.calcPrice()
      },
    },
    mounted() {
      this.$store.dispatch('listeners/fetchListenerItems', { idOrder: this.idOrder })
    },
    methods: {
      handleAddListeners(items) {
        for (let i = 0, l = items.length; i < l; i++) {
          let newForm = JSON.parse(JSON.stringify(LISTENER_FORM))
          newForm.id = items[i].info?.id
          newForm.surname = items[i].info?.surname
          newForm.surname_disabled = !!items[i].info?.surname
          newForm.name = items[i].info?.name
          newForm.name_disabled = !!items[i].info?.name
          newForm.patronymic = items[i].info?.patronymic
          newForm.patronymic_disabled = !!items[i].info?.patronymic
          newForm.email = items[i].info?.email
          newForm.email_disabled = !!items[i].info?.email
          newForm.avalible_vo_spo = items[i].info?.avalible_vo_spo
          newForm.avalible_vo_spo_disabled = !!items[i].info?.avalible_vo_spo
          newForm.phone = items[i].info?.phone
          newForm.phone_disabled = !!items[i].info?.phone
          newForm.snils = items[i].info?.snils
          newForm.snils_disabled = !!items[i].info?.snils
          this.selectedListeners.push(newForm)
        }
        this.setProgramBlocksToForm()
      },
      removeListener(index) {
        this.selectedListeners.splice(index, 1)
      },
      addNewListener() {
        let newForm = JSON.parse(JSON.stringify(LISTENER_FORM))
        this.selectedListeners.push(newForm)
        this.setProgramBlocksToForm()
      },
      calcPrice() {
        for (let i = 0, l = this.selectedListeners.length; i < l; i++) {
          let price = 0
          for (let j = 0; j < this.selectedListeners[i].group_program.length; j++) {
            if (this.selectedListeners[i].group_program[j].checked) {
              for (let k = 0; k < this.selectedListeners[i].group_program[j].blocks.length; k++) {
                price += Number(this.selectedListeners[i].group_program[j].blocks[k].price)
              }
            }
          }
          this.selectedListeners[i].price = price
        }
      },
      setProgramBlocksToForm() {
        if (!this.selectedProgram || this.selectedProgram && !this.selectedProgram) return
        for (let i = 0, l = this.selectedListeners.length; i < l; i++) {
          this.selectedListeners[i].group_program = this.selectedProgram.group_program.map(item => {
            return {
              ...item,
              checked: true,
            }
          })
        }
        this.calcPrice()
      },
      clearSearch() {
        this.searchProgram = ''
        this.searchedListProgram = []
      },
      hasHistory() {
        return window.history.length > 2
      },
      async submitForm() {
        const isValid = await this.$refs['observer-form-listener'].validate()
        if (isValid) {
          if (!this.selectedProgram) {
            this.$notify({
              group: 'center',
              title: ``,
              text: 'Выберите программу',
              type: 'error',
            })
          } else {
            if (!this.selectedListeners.length) {
              this.$notify({
                group: 'center',
                title: ``,
                text: 'Добавьте слушателей!',
                type: 'error',
              })
              return
            }

            const formData = new FormData()

            this.selectedListeners.forEach(item => {
              let _id = uniqid()
              if (item.id) {
                formData.append(`user_id[${_id}]`, item.id)
              }
              formData.append(`surname[${_id}]`, item.surname)
              formData.append(`name[${_id}]`, item.name)
              formData.append(`patronymic[${_id}]`, item.patronymic)
              formData.append(`email[${_id}]`, item.email)
              formData.append(`snils[${_id}]`, item.snils.replace(/[^\d]/g, ''))
              formData.append(`phone[${_id}]`, item.phone.replace(/[^\d]/g, ''))
              formData.append(`avalible_vo_spo[${_id}]`, item.avalible_vo_spo ? 1 : 0)
              item.group_program.forEach((group) => {
                group.blocks.forEach(block => {
                  formData.append(`group_program_id[${block.id}][${group.id}]`, group.checked ? 1 : 0)
                })
              })
            })
            formData.append(`program_id`, this.selectedProgram.program_id)
            formData.append(`configuration_id`, this.selectedProgram.id)

            let path = ''
            switch (this.user.role) {
              case 'contact_person':
                path = `/contact_person/orders/${this.idOrder}/bids/create`
                break
              case 'sales_department_manager':
                path = `/mop/orders/${this.idOrder}/bids/create`
                break
              case 'head_department_manager':
                path = `/rop/orders/${this.idOrder}/bids/create`
                break
            }
            axios({
              url: process.env.VUE_APP_API_URL + path,
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`,
              },
              data: formData,
            })
              .then(res => res.data)
              .then(res => {
                if (res.message) {
                  this.$notify({
                    group: 'center',
                    title: `Ошибка!`,
                    text: res.message,
                    type: 'error',
                  })
                } else {
                  this.$notify({
                    group: 'center',
                    title: ``,
                    text: `Заявка создана!`,
                    type: 'success',
                  })
                  this.$store.dispatch('orders/fetchOrderItem', this.$route.params.id)
                  this.$router.push({ path: `/orders/${this.$route.params.id}/` })
                }
              })
          }
        }
      },
      addSubmit() {

      },
      selectProgram(item) {
        this.selectedProgram = item
        //this.searchedListProgram = []
      },
      getSearchedList() {
        if (!this.searchProgram || this.searchProgram && this.searchProgram.length < 2) return

        let path = ''
        switch (this.user.role) {
          case 'contact_person':
            path = `/contact_person/orders/${this.idOrder}/bids/configurations/search`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${this.idOrder}/bids/configurations/search`
            break
          case 'head_department_manager':
            path = `/rop/orders/${this.idOrder}/bids/configurations/search`
            break
        }
        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            q: this.searchProgram,
          },
        })
          .then(res => res.data)
          .then(res => {
            this.searchedListProgram = res.data
          })
      },
    },
  }
</script>
