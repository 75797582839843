<template>
	<div class="table-responsive-md">
		<table class="table mb-0 align-middle">
			<thead>
			<tr>
				<th>Слушатель</th>
				<th>Контактные данные</th>
				<th>Блоки<br />программы</th>
				<th>Есть<br />ВО/СПО</th>
				<th>Объем<br />часов</th>
				<th>Вид<br />программы</th>
				<th>Форма<br />обучения</th>
				<th>Вид документа</th>
				<th>Стоимость</th>
				<th class="w-200px"></th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="listener in listeners" :key="'listener_' + idBid + '_' + listener.id">
				<td>
					{{[listener.info?.surname, listener.info?.name, listener.info?.patronymic].join(' ')}}
				</td>
				<td>
					{{$formatPhone(listener.info?.phone)}}<br />
					{{listener.info?.email}}
				</td>
				<td>
					<div v-for="block in listener.configs?.blocks" v-if="listener.configs?.blocks" :key="idBid + '_block_' + block.id">
						{{block.name}}
					</div>
				</td>
				<td>
					{{listener.info?.avalible_vo_spo ? 'Да' : 'Нет'}}
				</td>
				<td>
					{{listener.configs?.count_clock}}
				</td>
				<td>
					{{listener.configs?.programm_type}}
				</td>
				<td>
					{{listener.configs?.form_education}}
				</td>
				<td>
					{{listener.configs?.type_document}}
				</td>
				<td>
					<div v-for="block in listener.configs?.blocks" v-if="listener.configs?.blocks" :key="idBid + '_block_price_' + block.id">
						{{$formatPrice(block.price)}}
					</div>
				</td>
				<td nowrap="nowrap">
					<router-link :to="`/orders/${idOrder}/edit-listener/?ID_BID=${idBid}&ID_PROGRAM=${idProgram}&ID_LISTENER=${listener.id}`">
						<IconPen />
					</router-link>
					<DeleteListenerModal
							:id-order="idOrder"
							:id="listener.id"
							:is-bid="isBid"
							:program-name="nameProgram"
							:listener-fio="[listener.info?.surname, listener.info?.name, listener.info?.patronymic].join(' ')">
						<template slot="button">
							<IconTrash class="ms-12px" />
						</template>
					</DeleteListenerModal>
					<a href="javascript:;" @click.prevent="sendAccesses(listener.id)" class="btn btn-sm btn-primary ms-12px py-5px px-7px">Отправить доступы</a>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
  import axios from 'axios'

  import IconTrash from '@/components/icons/IconTrash'
  import IconPlus from '@/components/icons/IconPlus'
  import IconPen from '@/components/icons/IconPen'
  import DeleteListenerModal from './modal/DeleteListenerModal'

    export default {
      name: 'Listeners',
	  props: ['listeners', 'idOrder', 'idBid', 'idProgram', 'nameProgram', 'isBid'],
      components: { DeleteListenerModal, IconPlus, IconTrash, IconPen },
      data() {
        return {
        }
      },
	  computed: {
		token() {
          return this.$store.getters['auth/token']
        },
	  },
	  methods: {
        sendAccesses(listener_id) {
          if (!listener_id) return
          axios({
            url: process.env.VUE_APP_API_URL + `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/listeners/${listener_id}/send_access`,
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            },
          })
            .then(res => res.data)
            .then(res => {
              if (res.data && res.data.user_id) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: `Отправлено`,
                  type: 'success',
                })
              } else if (res.message) {
                this.$notify({
                  group: 'center',
                  title: `Ошибка`,
                  text: res.message,
                  type: 'error',
                })
			  }
            })
        },
	  }
    }
</script>
