import auth from './auth'
import user from './user'
import toast from './toast'
import orders from './orders'
import listeners from './listeners'
import documents from './documents'
import dictionaries from './dictionaries'
import bids from './bids'

export default {
  auth,
  user,
  toast,
  orders,
  listeners,
  documents,
  dictionaries,
  bids,
}
