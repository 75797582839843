<template>
	<div>
		<div v-if="user && user.need_edit_profile" class="fs-16px text-danger">
			Заполните профиль!
		</div>
	</div>
</template>

<script>

  export default {
    name: 'NeedProfileMessage',
    data() {
      return {
        searchProgram: '',
        searchedListProgram: [],
        selectedProgram: null,
        selectedListeners: [],
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
    }
  }
</script>
