<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Заказы</h1>
		</div>
		<!-- END page-header -->

		<div class="mw-1100px">
			<div class="border border-1 border-radius-lg border-primary px-25px py-15px mb-3 d-flex align-items-center" v-if="items" v-for="item in items" :key="item.id">
				<span class="fs-24px fw-bold me-4">Заказ №{{item.id}}</span>
				<span>от {{item.created_at}}</span>
				<router-link :to="'/orders/' + item.id" class="btn btn-primary btn-theme fw-bold ms-auto">Подробнее</router-link>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'ContactPersonOrderList',
    props: ['items'],
    data() {
      return {
        page: 1,
      }
    },
    computed: {},
    created() {
    },
    methods: {},
  }
</script>
