
<template>
	<div class="mw-900px">
		<div class="alert alert-success d-flex align-items-center mb-24px" v-if="user.another_documents_verified">
			<i class="fa fa-check me-12px"></i>
			Документы проверены
		</div>
		<div class="columns-2">
			<template v-for="field in documentsTypes" v-if="form[field.code] && documentsTypes.length">
				<div class="columns-item mb-15px position-relative" :key="`documents_form_${field.code}`">
					<label class="form-label">{{field.name}}</label>
					<InputFileField @setFiles="e => setFiles(e, field.code)" :name="field.code" :simple="true" :default-file-name="form[field.code].name" />
					<div v-if="errors[field.code]" class="invalid-tooltip">{{errors[field.code]}}</div>
				</div>
			</template>

		</div>

		<button type="submit" class="btn btn-primary px-30px py-10px mt-30px" :disabled="!hasChanges" @click="sendForm">Отправить на проверку</button>
	</div>
</template>

<script>
  import InputFileField from '@/components/elements/InputFile'
  import IconDocuments from '@/components/icons/IconDocuments'

  export default {
    name: 'PersonalDataDocumentsForm',
    components: { IconDocuments, InputFileField },
    data() {
      return {
        form: {},
        errors: {},
        hasChanges: false,
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      isListener() {
        return this.user && this.user.role === 'listener'
      },
      documents() {
        return this.$store.getters['user/other_documents']
      },
      documentsTypes() {
        return this.$store.getters['dictionaries/items']('listener_another')
      },
    },
    watch: {
      documentsTypes(newVal, oldVal) {
        newVal.forEach(type => {
          let name = ''
          this.documents.forEach(item => {
            if (type.code === item.type_code) {
              name = item.file_name
            }
          })
          this.form[type.code] = {
            file: null,
            type: type.code,
            name: name
          }
          this.errors[type.code] = false
        })
      },
    },
    mounted() {
      this.$store.dispatch('dictionaries/fetchDictionary', { type: 'type_documents', subtype: 'listener_another' })
      this.$store.dispatch('user/fetchGetUserOtherDocuments')
    },
    methods: {
      setFiles(e, code) {
        this.form[code].file = Array.isArray(e) ? e[0] : e
        this.hasChanges = true
      },
      sendForm() {
        let documents = []
        let data = new FormData()
        for (let key in this.form) {
          if (this.form.hasOwnProperty(key) && this.form[key].file) {
            documents.push({ document_type: this.form[key].type })
            data.append(`document_${this.form[key].type}`, this.form[key].file)
          }
        }
        documents.forEach((item, index) => {
          data.append(`documents[${index}][document_type]`, item.document_type)
          data.append(`documents[${index}][comment]`, 'comment')
        })
        if (documents.length) {
          this.$store.dispatch('user/fetchUpdateUserOtherDocuments', data).then(res => {
            if (res.message) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Отправлено на проверку`,
                type: 'success',
              })
            }
          })
        }
      },
    },
  }
</script>
