<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M7.99991 1.14453C4.22858 1.14453 1.14258 4.22986 1.14258 8.0012C1.14258 11.7732 4.22924 14.8585 7.99991 14.8585C11.7712 14.8585 14.8572 11.7732 14.8572 8.0012C14.8572 4.22986 11.7712 1.14453 7.99991 1.14453ZM7.99991 13.7159C4.85724 13.7159 2.28591 11.1445 2.28591 8.0012C2.28591 4.85853 4.85724 2.28786 7.99991 2.28786C11.1432 2.28786 13.7146 4.85853 13.7146 8.0012C13.7146 11.1445 11.1432 13.7159 7.99991 13.7159ZM7.99991 12.0032C8.11447 12.0064 8.2285 11.9866 8.33527 11.945C8.44204 11.9033 8.53938 11.8407 8.62153 11.7608C8.70369 11.6809 8.76899 11.5854 8.81358 11.4798C8.85817 11.3742 8.88115 11.2608 8.88115 11.1462C8.88115 11.0316 8.85817 10.9182 8.81358 10.8126C8.76899 10.707 8.70369 10.6115 8.62153 10.5316C8.53938 10.4517 8.44204 10.3891 8.33527 10.3474C8.2285 10.3058 8.11447 10.286 7.99991 10.2892C7.77676 10.2954 7.56484 10.3884 7.4092 10.5485C7.25356 10.7085 7.16648 10.923 7.16648 11.1462C7.16648 11.3694 7.25356 11.5839 7.4092 11.7439C7.56484 11.904 7.77676 11.997 7.99991 12.0032ZM5.79991 6.85986C5.79991 5.5452 6.71458 4.57386 7.99991 4.57386C9.28591 4.57386 10.1999 5.48853 10.1999 6.77386C10.1999 7.48853 9.68591 8.0312 9.22858 8.48853C8.91458 8.80253 8.57125 9.14586 8.57125 9.40253H7.42858C7.42858 8.68853 7.94325 8.14586 8.39991 7.68853C8.71458 7.37386 9.05725 7.0312 9.05725 6.77386C9.05725 6.1172 8.65725 5.7172 7.99991 5.7172C7.37124 5.7172 6.94324 6.17386 6.94324 6.85986H5.79991Z" :fill="color"/>
	</svg>
</template>

<script>
  export default {
    name: 'IconHelp',
	props: {
      'width': {
		type: Number,
		default: 16,
	  },
	  'height': {
        type: Number,
        default: 16,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#6A6C6A'
	  },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
