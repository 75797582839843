<template>
	<div>
		<div class="d-flex justify-content-between align-items-center" v-if="price">
			<div class="ms-auto text-end">
				<div class="text-muted mb-12px">Итого</div>
				<div class="fs-24px fw-600">
					{{$formatPrice(price)}} ₽
				</div>
			</div>
		</div>
	</div>
</template>

<script>

  export default {
    name: 'OrderTotalCP',
    props: ['price'],
    data() {
      return {}
    },
    methods: {},
  }
</script>
