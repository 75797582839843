import state from './dictionariesState'
import mutations from './dictionariesMutations'
import getters from './dictionariesGetters'
import actions from './dictionariesActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
